import { Grid } from '@mui/material'
import React, { useState, useContext,useRef, useEffect } from 'react'
import { AuthContext } from "../auth/Auth";
import { MaterialCreatorMetadataModal, materialCreator } from '../components/MaterialCreator';
import { useLoginState } from "../studioxr/auth/LoginState";
import { gtagViewItemEvent, handleMeshChange, resizeImage } from '../libs/util';
import { ProductInterface, MaterialData,ProductType,ObjectStatusType, ProfileFirstActions, ProfileInterface, UserProfileRole } from 'ts-interfaces';
import { cloudflareCDN, generateID } from 'ui';
import { useMattoState, usePhysicalObjects, useProjectData } from '../MattoState';
import { SideBarMode } from '../ts/app_interfaces';
import { deleteRecordAndFiles, userProductsDB, MAX_FREE_MATERIALS, userProductFiltersDB, firebaseDB} from '../libs/firebase';
import { query, where, getDocs, orderBy, limit,startAfter,or, and } from "firebase/firestore";
import { ScrollableGrid } from './ScrollableGrid';
import { produce } from 'immer';
import useEventListener from '../libs/useEventListener';
import YoutubeIcon from '@mui/icons-material/YouTube';
import { FabHelp } from '../components/FabHelp';
import { PaintCreator } from '../components/PaintCreator';
import { filterProductToDraggablePaint } from '../libs/product_helpers';
import structuredClone from '@ungap/structured-clone';
import { MyMaterialFilter } from './MyMaterialFilter';
import { SearchFilter } from '../instasearch/SearchFilter';
import { ActionButton } from '../buttons/ButtonStyles';
import { UserProductFiltersInterface } from 'ts-interfaces';
import { doc, getDoc, updateDoc } from "firebase/firestore";

const imageAccept=".webp,.jpg,.png,.jpeg,.PNG,.JPG,.WEBP,.JPEG,.avif,.AVIF" 

export const CreateMaterialTab = () => {
	const { currentUser } = useContext(AuthContext);
    const fileRef:any = useRef()
    const modalState = materialCreator(state=>state.modalState)
    const setModalState = materialCreator(state=>state.setModalState)
    const setShowLogin = useLoginState((state) => state.setShowLogin);
    const saving = materialCreator(state=>state.saving)
    const sideBarMode:any = useMattoState((state) => state.sideBarMode);
    const [product,setProduct]=useState<ProductInterface | null>()
    const [materials,setMaterials]=useState<any[]>([])
	const [materialsLoaded, setMaterialsLoaded]=useState(false)
	const [isLoading,setIsLoading]=useState(false)
    const rootMaterialContainer = useRef<any>()
    const [brandFilters, setBrandFilters] = useState<any[]>([])
    const [categoryFilters, setCategoryFilters] = useState<any[]>([])

    const getCurrentSelectedPhysicalObject = usePhysicalObjects( (state) => state.getCurrentSelectedPhysicalObject)
    const getCurrentThreeJSObject = usePhysicalObjects( (state) => state.getCurrentThreeJSObject)
	const setProjectData = useProjectData(state=>state.set)	    
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)

    useEffect( () => { loadItems(); loadFilters(); }, [currentUser?.uid])

    useEventListener('userMaterialUpdate', (e) => {
        const material = e.detail?.material
        if (material) {
            const newMaterials = produce(materials, draft=> {
                const index = draft.findIndex(m => m.id == material.id)
                if (index >= 0) draft[index] = material
            })
            setMaterials(newMaterials)
        }
    },document as any)


    const loadItems = () =>{
		if (isLoading==false && materialsLoaded==false) {
			loadMaterials(false)
		}
	}
    const loadMaterials = async(clearMaterials=true)=> {
        if (isLoading || SideBarMode.YourMaterials != sideBarMode) return
        if (!materials || (!currentUser?.uid)) return;

        setIsLoading(true)

        const a:any = [userProductsDB];
        const brandsToFilter = brandFilters.filter( (b) => b.isRefined).map( (b) => b.brand)
        const categoriesToFilter = categoryFilters.filter( (c) => c.isRefined).map( (c) => c.category)

        if(brandsToFilter.length > 0 && categoriesToFilter.length > 0) {
            a.push(and(
                    where("uid", "==", currentUser.uid),   
                    or(
                        where("metadata.brand", "in", brandsToFilter),
                        where("category", "array-contains-any", categoriesToFilter)
                    )))
        } else {
            a.push(where("uid", "==", currentUser.uid));
            if(brandsToFilter.length > 0) {
                a.push(where("metadata.brand", "in", brandsToFilter))
            } 
            if(categoriesToFilter.length > 0) {
                a.push(where("category", "array-contains-any", categoriesToFilter))
            }
        }
        a.push(orderBy("updatedAt","desc"),limit(20))

        if (materials.length > 0 && clearMaterials == false) {
            a.splice(a.length-1, 0, startAfter(materials[materials.length-1].updatedAt))
		}
		const q = query.apply(this, a)
        getDocs(q).then ( querySnapshot => {
			setMaterialsLoaded(querySnapshot.size == 0)
			const prevMaterials = clearMaterials ? [] : materials
			const materialsTemp:any = []
			querySnapshot.forEach((doc:any) => {materialsTemp.push(doc.data()) })
			const newMaterialsValue = [...prevMaterials, ...materialsTemp]
			if(newMaterialsValue.length > 0) setMaterials(newMaterialsValue);
        })
        .catch((error) => { console.log("Error getting userMaterials: ", error); })
        .finally( () => { setIsLoading(false); })
    }

    const loadFilters = async() => {
        if (!currentUser?.uid) return;

        const a:any = [userProductFiltersDB, where("uid", "==", currentUser.uid)]   
        const q = query.apply(this, a)
        const querySnapshot = await getDocs(q);  
        if (querySnapshot.docs.length > 0) {
            const filters: UserProductFiltersInterface | any = querySnapshot.docs[0].data()
            if(filters?.brands?.length > 0) {
                // set isRefined to false for all brands
                filters.brands = filters.brands.map( (b) => { return {...b, isRefined: false} })
                setBrandFilters(filters.brands)
            }
            if(filters?.categories?.length > 0) {
                // set isRefined to false for all categories
                filters.categories = filters.categories.map( (c) => { return {...c, isRefined: false} })
                setCategoryFilters(filters.categories)
            } 
        }
    }

    useEffect( () => {
        if (!currentUser?.uid || sideBarMode != SideBarMode.YourMaterials) return
    },[sideBarMode])

	const checkIfUserCanUse = () => {		
		const profile:ProfileInterface | any= useLoginState.getState().profile
        if (!profile?.uid)  {  setShowLogin('signup'); return false }        
		if (materials?.length >= MAX_FREE_MATERIALS && profile.userRole!=UserProfileRole.PRO) {
			window?.gtag?.('event','max_materials_reached')
			useMattoState.getState().setShowSubscription(['startTrial', 'You have used  all of your free materials! Sign up for Pro for Unlimited Materials.']); 
			return  false
		}
		return true
	}

    const handleFiles = (e) => {
        if (!currentUser?.uid) { setShowLogin('signup'); return; }
        if (!checkIfUserCanUse()) return;
        const file = e.target.files?.[0]
        if (file) {
            resizeImage(file,700,512,file.name).then( (imgCanvas:any) => {                
                const product = createProduct(currentUser.uid, imgCanvas, file.name,true)
                fileRef.current.value=''
                setProduct(product)
                setModalState('metadata')
            })
            .catch ( (e) => { alert("Error uploading this image " + e); })    
        }
    }
    const onDrag = (e,product:ProductInterface) =>  { 
        if (product.productType==ProductType.PAINT)  e.dataTransfer.setData("paint", JSON.stringify(filterProductToDraggablePaint(product)))
        else e.dataTransfer.setData("material", JSON.stringify(structuredClone(product)));
    }

    const handlePaintCreatorClick = () => {
        if (!currentUser?.uid) { setShowLogin('signup'); return; }
        if (!checkIfUserCanUse()) return;
        setProduct(null)
        setModalState('paint_metadata')
    }

    const handleClick = (product:ProductInterface) => { 
        gtagViewItemEvent(product)
        const idx = usePhysicalObjects.getState().selectedMaterialndex || 0
        handleMeshChange(product,getCurrentThreeJSObject,getCurrentSelectedPhysicalObject,setProjectData,updatePhysicalObject, idx)        
     }
	const handleMouseOver = (e,id) =>  { e.preventDefault(); 
        const d = document.getElementById(id+'delIcon')
        if (d) d.style.visibility = 'visible'
    }
	const handleMouseOut = (e,id) =>   { e.preventDefault();  
        const d = document.getElementById(id+'delIcon')
        if (d) d.style.visibility = 'hidden' 
    }
    const getImageSrc = (material) =>  { 
        if (material.productType==ProductType.PAINT) {
            if (material.materialData.files.color_original.startsWith('data:image')) return material.materialData.files.color_original;    
            else return cloudflareCDN(material.materialData.files.color_original,'height=120,format=auto');
        }    
        else return cloudflareCDN(material.materialData.files.color_original,'height=120,format=auto'); 
    }
    const trimUploadName = (material) => material.name;

	const deleteIconOnclick = (e,material) => {
		if (window.confirm('Are you sure you wish to delete this material?')){	
            e.stopPropagation()
            deleteRecordAndFiles(material.id,"userProducts").then( (result) => {
                const newMaterials = materials.filter(u => u?.id != material.id)
                setMaterials(structuredClone(newMaterials))
            })
	    }
		else { e.stopPropagation() }
	}
    const handleNewMaterial = (m) => {        
        const newMaterials = produce(materials, draft=>{
            const index = draft.findIndex(c => c.id === m.id)
            if (index >= 0) draft[index] = m
            else  draft.unshift(m)                
        })
        setMaterials(structuredClone(newMaterials))
        checkFiltersUpdated()
    }

    const checkFiltersUpdated = async () => {
        let attempts = 0;
        const maxAttempts = 5;
      
        const poll = async () => {
          if (attempts >= maxAttempts) {
            console.log("Max polling attempts reached.");
            return;
          }
      
          const userFiltersRef = doc(firebaseDB as any, "userProductFilters", currentUser.uid);
          const docSnap = await getDoc(userFiltersRef);
          if (docSnap.exists() && docSnap.data().filtersUpdated) {
            loadFilters();
            await updateDoc(userFiltersRef, { filtersUpdated: false });
            return;
          }
      
          attempts++;
          const delay = Math.pow(2, attempts) * 1000; // Exponential backoff starting with 1 second
          setTimeout(poll, delay);
        };
      
        poll();
      };

    const handleInfoClick = (e,materialID) => {
        const p:ProductInterface = materials.filter(m=> m.id==materialID)?.[0]
        if (p) {
            setProduct(p)            
            setModalState(p.productType == ProductType.PAINT ? 'paint_metadata'  : 'metadata')
        }
    }
    const handleNewMaterialClick = (ee) => {
        if (!currentUser?.uid) { setShowLogin('signup'); return; }
        if (!checkIfUserCanUse()) { 
            ee.preventDefault()
            ee.stopPropagation()
            return;
        }
        //fileRef.current.click()
    }

    if (sideBarMode != SideBarMode.YourMaterials)   return (null)
    return (
        <>
        {modalState=='metadata' &&  <MaterialCreatorMetadataModal onSavedProduct={handleNewMaterial} incomingProduct={product} /> }
        {modalState=='paint_metadata' &&  <PaintCreator onSavedProduct={handleNewMaterial} incomingProduct={product} /> }

        <Grid container ref={rootMaterialContainer} spacing={1} style={{paddingTop:'17px',paddingLeft:'8px'}} >
        <Grid item xs={12}>
            <ActionButton onClick={handleNewMaterialClick} variant='contained'  disabled={saving===true || (!currentUser?.uid) } component="label">
            {currentUser?.uid ? 'Create New Material' : 'Login to Create Materials'  }
            <input type="file"  ref={fileRef} onChange={ (e) => { handleFiles(e); } } hidden accept={imageAccept} />
            </ActionButton>

            <ActionButton onClick={handlePaintCreatorClick} style={{marginTop:'10px'}} variant='contained'  disabled={saving===true || (!currentUser?.uid) }>
            {currentUser?.uid ? 'Create New Paint' : 'Login to Create Paints'  }
            </ActionButton>
        </Grid>
        {currentUser?.uid &&
            <SearchFilter boundingBox={rootMaterialContainer }  side='left' typesenseFilter = {false} >
                <MyMaterialFilter brands={brandFilters} categories={categoryFilters} setBrandFilters={setBrandFilters} setCategoryFilters={setCategoryFilters} loadMaterials={loadMaterials} />
            </SearchFilter>
        }
        <ScrollableGrid
			    style={{maxHeight:'85vh', overflow:'auto', paddingTop:'10px', paddingLeft:'4px'}}
			    getName={trimUploadName}
				getSrc={getImageSrc} 
				draggable={true} 
				onDragStart={onDrag}
				deleteIconOnclick={deleteIconOnclick} 
				onMouseOver={handleMouseOver} 
				onMouseOut={handleMouseOut} 
				loadItems={loadItems}
				items={materials}
                onClick={handleClick}
                showInfoIcon={true}
                onInfoClick={handleInfoClick}
				type='myMaterials'
                currentUserUid={currentUser?.uid}/>	
        </Grid>        

        <FabHelp firstaction={ProfileFirstActions.VISIT_UPLOAD_MATERIAL} fabkey='youtubehelp' url='https://www.youtube.com/watch?v=DCQKcJE6WdQ' variant='extended' style={{position:'absolute', bottom:16, left:16}}>
            <YoutubeIcon sx={{ mr: 1 }} />
            Learn How to Create Materials on YouTube
        </FabHelp>
        </>
    )
}

function createProduct(userID:string, colorMap:string,name='', createNormalMap:boolean=true) {
    const materialData:MaterialData = {
        id: '',
        name: name,
        files: { color_original: colorMap }
    }
    const product:ProductInterface={
        productType: ProductType.MATERIAL,
        objectStatus: ObjectStatusType.USER_CREATED,
        isStaticObject: false,
        updatedAt: Date.now(),
        uid: userID,
        id: generateID(),
        name: name, 
        textureType: [],
        materialData: materialData,
    }
    return product;
}
