import React from "react";
import { Grid } from "@mui/material";

export const VerificationScreen = () => {
    return (
        <Grid sx={styles.container}>
        <style>
            {`
            @keyframes spin {
                0% {
                transform: rotate(0deg);
                }
                100% {
                transform: rotate(360deg);
                }
            }
            `}
        </style>
          <Grid style={styles.spinner}></Grid>
          <p style={styles.text}>Verification in progress...</p>
        </Grid>
      );
}

const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      backgroundColor: "#f8f9fa",
      fontFamily: "Arial, sans-serif",
    },
    spinner: {
      width: "40px",
      height: "40px",
      border: "4px solid #dee2e6",
      borderTop: "4px solid #007bff",
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
    },
    text: {
      marginTop: "10px",
      fontSize: "16px",
      color: "#343a40",
    },
};