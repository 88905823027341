import React, { useRef,useState,useMemo,useEffect, Suspense } from 'react'
import { PhysicalObjectInterface } from '../../ts/app_interfaces';
import { useGLTF} from '@react-three/drei';
import {getUrlFromRef} from '../../libs/firebase.js';
import { Mesh, Vector3, Matrix4 } from 'three';
import { calculateRepeatModifier } from '../../libs/util';
import { mergeGeometries } from '../stdlib/BufferGeometryUtils';
import {PhysicalMaterial} from '../PhysicalMaterial';
import { MaterialData } from 'ts-interfaces';
import { useBVH } from '../../components/useBVH';


export const ExpandedObject = (props) => {
    if(props.physicalObject?.version==2 && props.physicalObject?.isStaticObject==true) {
        return ( <Suspense fallback={null} ><ExpandedStaticObject2 {...props} /></Suspense>)
    }
    else {
        return ( <Suspense fallback={null} ><ExpandedAsyncGLTFObject {...props}  /></Suspense> )
    }
}

interface AsyncGLTFProps {
    physicalObject: PhysicalObjectInterface;
    size: string;
    textureRepeatModifier?:number
    onLoad: () => void;
}

const ExpandedAsyncGLTFObject = ({ physicalObject, size = 'small', onLoad }: AsyncGLTFProps) => {
    const gltfFile = physicalObject.mesh?.web_sized_glb || physicalObject.url || physicalObject.files?.[size] || physicalObject.files?.draco_normal;
    const gltf: any = useGLTF(getUrlFromRef(gltfFile));
    const meshes = useMemo(() => {
        const entries: any = Object.entries(gltf.nodes);
        return entries
            .filter(i => i[1].type === 'Mesh')
            .map(m => m[1])
            .slice(physicalObject.meshIndex ?? 0, physicalObject.meshIndex == null ? 1000 : physicalObject.meshIndex + 1)
            .map(m => m.clone());
    }, [gltf]);
    
    const [loaded, setLoaded] = useState(false);
    const [tileDimensions, setTileDimensions] = useState({ x: 0, y: 0, z: 0 });
    const [tilesRendered, setTilesRendered] = useState(false);

    const groupRef = useRef<any>();
    const xCount = physicalObject.metadata?.x || 1;
    const yCount = physicalObject.metadata?.y || 1;

    const scaleFactor = (physicalObject.scale[0] || 1) / (physicalObject.originalScale || 1) || 1
    const worldScale = new Vector3();
    groupRef.current?.getWorldScale(worldScale);

    useBVH(groupRef);

    useEffect(() => {
        if (groupRef.current && tileDimensions.x && tileDimensions.z && !tilesRendered) {
            setTilesRendered(true);
        }
    }, [tileDimensions]);

    useEffect(() => {
        if (tilesRendered) onLoad?.();
    }, [tilesRendered]);

    useEffect(() => {
        if (groupRef.current && loaded) {
            const xPos = physicalObject?.metadata?.expand?.posX || 0.1;
            const yPos = physicalObject.position[1];
            const zPos = physicalObject?.metadata?.expand?.posY || 0.1;
            setTileDimensions({ x: xPos, y: yPos, z: zPos });
        }
    }, [physicalObject.metadata?.expand?.posX, physicalObject.metadata?.expand?.posY]);

    useEffect(() => {
        onLoad?.(); // update bvh computation
    }, [physicalObject.metadata?.x, physicalObject.metadata?.y]);

    const textureRepeatModifier = calculateRepeatModifier(physicalObject)

    let isSingleMesh = physicalObject.mergeGeo==true || (meshes.length==1 && meshes[0].material?.map==null)
    if (physicalObject.isStaticObject==true) isSingleMesh = false

    const geometry = useMemo(() => {
		if (!meshes || isSingleMesh==false) {  setLoaded(true); return null; }
		if (physicalObject.mergeGeo) {
			const geos:any=[]
			gltf.scene.traverse(obj => {if (obj.isMesh) {  geos.push(obj.geometry) }})
			const merged =  mergeGeometries(geos)
			merged.center()
			setLoaded(true); 
			return merged
		}
		else { meshes[0].geometry?.center(); setLoaded(true); return meshes[0].geometry }
	},[meshes])

    const scaledMesh = !(physicalObject.scale?.[0] == physicalObject.scale?.[1] && physicalObject.scale?.[0] == physicalObject.scale?.[2])

    if (isSingleMesh) {
        return (
            <group ref={groupRef}>
                {Array.from({ length: yCount }).map((_, row) =>
                    Array.from({ length: xCount }).map((_, col) => {
                        const clonedGeometry = geometry.clone();
                        const matrix = new Matrix4();
                        const position = calculatePosition(col, row, tileDimensions, worldScale, scaleFactor);
                        // Apply transformation to geometry
                        matrix.setPosition(position);
                        clonedGeometry.applyMatrix4(matrix);
                        return (
                            <mesh key={`tile-${row}-${col}`} castShadow receiveShadow  geometry={clonedGeometry}>
                                <PhysicalMaterial  material={physicalObject.materialData as MaterialData}  scaledMesh={scaledMesh} textureRepeatModifier={textureRepeatModifier} />
                            </mesh>
                        );
                    }))
                }
            </group>
        );
    }
    else if (physicalObject.version==2) {
        return (
            <group ref={groupRef}>
                {Array.from({ length: yCount }).map((_, row) =>
                    Array.from({ length: xCount }).map((_, col) => {
                        const position = calculatePosition(col, row, tileDimensions, worldScale, scaleFactor);
                        return (
                            <primitive castShadow receiveShadow  object={gltf.scene.clone()} position={[position.x, position.y, position.z]} dispose={null} />
                        );
                    })
                )}
            </group>
        );
    }
    else {
        return (
            <group ref={groupRef}>
                {meshes.map((mesh, index) => (
                    Array.from({ length: yCount }).map((_, row) =>
                        Array.from({ length: xCount }).map((_, col) => {
                            const clonedGeometry = mesh.geometry.clone();
                            const matrix = new Matrix4();
                            const position = calculatePosition(col, row, tileDimensions, worldScale, scaleFactor);

                            // Apply transformation to geometry
                            matrix.setPosition(position);
                            clonedGeometry.applyMatrix4(matrix);
                            const tileClone = new Mesh(clonedGeometry, mesh.material.clone());
                            tileClone.name = mesh.name;

                            return (
                                <primitive
                                    key={`tile-${index}-${row}-${col}`}
                                    object={tileClone}
                                    castShadow = {mesh.name.includes('inside') ? false : true} 
                                    receiveShadow = {mesh.name.includes('inside') ? false : true} 
                                    dispose={null}
                                />
                            );
                        })
                    )
                ))}
            </group>
        );
    }
};

const ExpandedStaticObject2 = ( {physicalObject, size='web_sized_glb',onLoad}:AsyncGLTFProps) => {			
    const gltf:any =  useGLTF(getUrlFromRef(physicalObject.mesh?.[size] || physicalObject.url || physicalObject.mesh?.full_sized_glb))
    const clone = useMemo(() => gltf.scene.clone(), [gltf])
    const [loaded,setLoaded]=useState(false)
    const groupRef = useRef<any>();
    const [tileDimensions, setTileDimensions] = useState({ x: 0, y:0, z: 0 });
    useBVH(groupRef);
    const xCount = physicalObject.metadata?.x || 1; // Number of horizontal copies
    const yCount = physicalObject.metadata?.y || 1; // Number of vertical copies
    const [tilesRendered, setTilesRendered] = useState(false);
    
    useEffect(() => {
        if (groupRef.current && !loaded) {
            setLoaded(true);
			clone.traverse( m => { if (m.isMesh) { m.castShadow=true; m.receiveShadow=true; }} )

            const xPos = physicalObject?.metadata?.expand?.posX || .1;
            const yPos = physicalObject.position[1];
            const zPos = physicalObject?.metadata?.expand?.posY || .1;

            // Update dimensions only if they haven't been set yet
            if (!tileDimensions.x || !tileDimensions.z) {
                setTileDimensions({ x: xPos, y: yPos, z: zPos });
            }
            onLoad?.();
        }
    }, [groupRef]);

    useEffect(() => {
        if (groupRef.current && tileDimensions.x && tileDimensions.z && !tilesRendered) {
            setTilesRendered(true);
        }
    }, [tileDimensions]);

    useEffect(() => {
        if (tilesRendered) onLoad?.();
    }, [tilesRendered]);

    useEffect(() => {
        onLoad?.(); // update bvh computation
    }, [physicalObject.metadata?.x, physicalObject.metadata?.y]);

    useEffect(() => {
        // detect changes in posX and posY
        if (groupRef.current && loaded) {
            const xPos = physicalObject?.metadata?.expand?.posX || .1;
            const yPos = physicalObject.position[1];
            const zPos = physicalObject?.metadata?.expand?.posY || .1;
            setTileDimensions({ x: xPos, y: yPos, z: zPos });
        }
    }, [physicalObject.metadata?.expand?.posX, physicalObject.metadata?.expand?.posY]);

    const scaleFactor = (physicalObject.scale[0] || 1) / (physicalObject.originalScale || 1) || 1

    // Get the parent's world scale
    const worldScale = new Vector3();
    groupRef.current?.getWorldScale(worldScale);

    return (
        <group ref={groupRef}>
            {Array.from({ length: yCount }).map((_, row) =>
                Array.from({ length: xCount }).map((_, col) => {
                const tileClone = clone.clone();
                const position = calculatePosition(col, row, tileDimensions, worldScale, scaleFactor);
                return (
                    <primitive
                    key={`object-${row}-${col}`}
                    object={tileClone}
                    position={[position.x, position.y, position.z]}
                    castShadow
                    receiveShadow
                    dispose={null}
                    />
                );
                })
            )}
        </group>
    );
};

function calculatePosition(col: number, row: number, tileDimensions: any, worldScale: Vector3, scaleFactor: number) {
    if (worldScale.x === 0 || worldScale.y === 0 || worldScale.z === 0) {
        return new Vector3(
            col * tileDimensions.x * scaleFactor,
            tileDimensions.y * scaleFactor,
            row * tileDimensions.z * scaleFactor
        );
    } else {
        return new Vector3(
            (col * tileDimensions.x * scaleFactor) / worldScale.x,
            (tileDimensions.y * scaleFactor) / worldScale.y,
            (row * tileDimensions.z * scaleFactor) / worldScale.z
        );
    }
};