import React, { useEffect, useContext } from 'react'
import {Board} from './threejs/Board.jsx'
import {Projects} from './projects/Projects.jsx'
import {Welcome} from './components/Home.jsx'
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router,  Routes,  Route, useLocation, Navigate, Outlet} from "react-router-dom";
import { AuthProvider } from "./auth//Auth";
import {Project} from './projects/Project'
import { Show } from './projects/Show'
import { AuthContext } from "./auth/Auth";
// import { MaterialBuilder } from './builder/MaterialBuilder'
// import { PaintBuilder } from './builder/PaintBuilder';
// import { GLTFBuilder } from './builder/GLTFBuilder'
//import { MetaSKU } from './builder/MetaSKU'
// import { MetaSKUConverter } from './builder/MetaSKUConverter.tsx'
//import { CreateMaterial2 } from './builder/CreateMaterial2'
// import { MaterialImageRender } from './builder/MaterialImageRender'
//import { MetaSKUUpscale } from './builder/MetaSKUUpscale.tsx'
// import { MetaSKUSeamless } from './builder/MetaSKUSeamless.tsx'
// import { MetaSKUShadowRemoval } from './builder/MetaSKUShadowRemoval.tsx'
import { getGPUTier } from 'detect-gpu';
import {useMattoState} from './MattoState.ts'
import { StudioXRHome } from './studioxr/studioxr'
// import { MyCollections } from './studioxr/collections/collection';
import theme from './theme.js';
// import CollectionItems from './studioxr/CollectionItems';
import Brand from './studioxr/Brand'
import Brands from './studioxr/Brands'
import { GLTFBuilderLazy, MaterialBuilderLazy, MetaSKULazy,CreateMaterial2Lazy,MetaSKUConverterLazy,
        MetaSKUUpscaleLazy, MetaSKUSeamlessLazy,MetaSKUShadowRemovalLazy,
        PaintBuilderLazy,MaterialImageRenderLazy } from './builder/BuilderLazy.tsx';
import { doc } from 'firebase/firestore';
//import { MetaSKU } from './builder/MetaSKU';
//import Discover from './studioxr/discover';
//import { fetchData } from './libs/util.ts';



const ProtectedRoute = ({ isAllowed, children }) => {
  if (!isAllowed) {
    return <Navigate to={'/'} replace />;
  }

  return children ? children : <Outlet />;

};

function usePageViews() {
  
  let location = useLocation();
  React.useEffect(() => {
    if (window.gtag) {
      gtag('set', 'page_path', window.location.pathname);
      gtag('event', 'page_view');
    }
  }, [location]);
}

function SwitchApp() {
  const {currentUser} = useContext(AuthContext);

  usePageViews();
  return (
    <Routes>  
    <Route
        path="/project/"
        element={<Navigate to="/project/new" replace element={<Project />} />}
    />
    <Route  exact path="/project/:id" element={<Project  />} />
    <Route  exact path="/show/:id" element={<Show />} />

    
    {/* <Route exact path="/builder/paintBuilder/edit/:id" element={<PaintBuilder />} /> */}
    {/* <Route  exact path="/builder/materialBuilder/edit/:id" element={<MaterialBuilder />} /> */}

    <Route element={<ProtectedRoute isAllowed={!!currentUser} />}>
      {/* <Route  exact path="/my-collections" element={<MyCollections />} /> */}
      {/* <Route path="/my-collection/:collectionId" element={<CollectionItems />} /> */}
        <Route  exact path="/projects/" element={<Projects />} />
        <Route  exact path="/board/" element={<Board />} />
        <Route  exact path="/board/:id" element={<Board />} /> 
        {/* <Route  exact path="/builder/metasku/new" element={<MetaSKU />}  /> */}
    </Route>

    {/* <Route path="/discover" element={<Discover />} /> */}
    <Route path="/brand" element={<Brand />} />
    <Route path="/brand/:slug" element={<Brand />} />
    <Route path="/brands" element={<Brands />} />

      <Route  exact path="/builder/paintBuilder/edit/:id" element={<PaintBuilderLazy />} />
      <Route  exact path="/builder/materialBuilder/edit/:id" element={<MaterialBuilderLazy />} />
      <Route  exact path="/builder/renders/edit/:id" element={<MaterialImageRenderLazy />} />
      <Route  exact path="/builder/accessory/edit/:id" element={<GLTFBuilderLazy />} />
      <Route  exact path="/builder/objectBuilder/edit/:id" element={<GLTFBuilderLazy meshType={'not_static'} />} />
      <Route  exact path="/builder/convert" element={<MetaSKUConverterLazy />} />
      <Route  exact path="/builder/upscale" element={<MetaSKUUpscaleLazy />} />
      <Route  exact path="/builder/make_seamless" element={<MetaSKUSeamlessLazy />} />
      <Route  exact path="/builder/createMaterial" element={<CreateMaterial2Lazy />} />
      <Route  exact path="/builder/shadowRemoval" element={<MetaSKUShadowRemovalLazy />} />
      <Route  exact path="/builder/metasku/new" element={<MetaSKULazy />}  /> 

    <Route element={<ProtectedRoute isAllowed={!!currentUser && currentUser.email?.toLowerCase().endsWith('mattoboard.com')} />}>
      <Route  exact path="/admin/materials/edit/:id" element={<MaterialBuilderLazy updateRecord={true} />} />
      <Route  exact path="/admin/objects/edit/:id" element={<GLTFBuilderLazy updateRecord={true} meshType={'not_static'} />} />
      <Route  exact path="/admin/accessories/edit/:id" element={<GLTFBuilderLazy updateRecord={true} />} />

    </Route>
    {/* <Route path="/my-collection/:collectionId" element={<CollectionItemCover />} /> */}


    <Route  exact path="/welcome" element={<Welcome />} />

    <Route  exact path="/founding" element={<StudioXRHome />} />
    <Route exact path="/" element={<StudioXRHome />} />
    <Route exact path="/start_login" element={<StudioXRHome startDesigning={true} />} />
    <Route exact path="/boards" element={<StudioXRHome />} />

    <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>

  )
}



function App() {
  useEffect(() => {
    window._AutofillCallbackHandler = window._AutofillCallbackHandler || function(){}

    let isLocalStorageAvailable = false;
    try {
      // Test localStorage availability
      const test = '__test__';
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      isLocalStorageAvailable = true;
    } catch (error) {
      isLocalStorageAvailable = false;
      console.error('LocalStorage is not available:', error);
    }

    if (isLocalStorageAvailable) {
      localStorage.user_session = localStorage.user_session || Math.random().toString();
    }

    const getGPUData = async () => {
      try {
        let gpuData = JSON.parse( isLocalStorageAvailable ? localStorage.gpuData ?? null : null)
        if (!gpuData) {
          gpuData = await getGPUTier();   
          if (isLocalStorageAvailable) localStorage.gpuData = JSON.stringify(gpuData)
          console.log("Getting GPU Data from API", gpuData);
          //logEvent(analytics, 'gpu', { fps: gpuData?.fps ?? 0 })
          if (window.gtag) window.gtag("event", "gpu", { fps: gpuData?.fps ?? 0 })

        }
        console.log("Cached GPU Data is ", gpuData)
        useMattoState.setState({ gpuData:gpuData })
        //if (window.dataLayer) window.dataLayer.push({'fps':gpuData?.fps})    
      }
      catch (error) {
        if (isLocalStorageAvailable) localStorage.removeItem('gpuData')
        console.log("Error getting GPU Data", error)
        useMattoState.setState({ gpuData:{"fps":0, "tier":1} })
      }
    }
    getGPUData()
  },[])

  return (
      <ThemeProvider theme={theme}>
      <AuthProvider>
      {/* <AuthProvider uid='3pEkUMP38ySutmxDhiXHyBASbY93'> */}
      <Router>
      <SwitchApp />
      </Router>
      </AuthProvider>
      </ThemeProvider>
  );
}



export default App

