import { MaterialData,MaterialFiles, MaterialProperties, ObjectStatusType, ProductInterface, GLBMesh, ProductType } from "../../../../packages/ts-interfaces"
import { generateID } from "../libs/util"

export enum ModalMode {
    Welcome,
    HelpCenter    
}
export enum InspectorMode {
    Properties,
    Materials,
    Objects,
    CanvasObject
}
export interface EnvironmentalMap {
    name:string
    preset:string
    small_1k:string
    large_4k:string
    thumbnail:string
}
export interface PhysicalObjectInterface {
    name:string
    key:number
    meshIndex?:number // index of mesh in scene.children
    url?:string  // url to for the specific gltf file.
    files?:GLTFFiles  // collection of resiized gltf files.
    position:Array<number>
    rotation:Array<number>
    scale:Array<number>
    type: ProductType
    category?:string
    materialData?:MaterialData
    isStaticObject:boolean   //Specifically can the texture of the object be changed?
    mergeGeo?:boolean  // force system to merge the geometry of the object.
    meshId?:string
    refId?:string //id that references the gltfmesh.
    meshTextureRepeat:number
    originalScale?:number //original scale of the object.
    mode?:string
    meshName?:string
    aspect?:number
    version?:number
    preset?:string,
    presets?:Array<string>,
    flipY?:boolean
    centered?:boolean
    envMapIntensity?:number
    paintMaterials?:Array<MaterialProperties>
    paintMaterialsData?:Array<MaterialData>
    dynamicMaterialProps?:DynamicMaterialProps
    canvasObject?:CanvasObject
    dimensionData?:Array<number>
    mesh?:GLBMesh
    metadata?:Metadata
    product_group_id?:string
}

export interface DynamicMaterialProps {
    files?:object,
    repeat?:object
    offset?:object,
    width?:number,
    height?:number
    metadata?:UploadedMetadata,
    backgroundRemoved?: boolean,
    originalFile?: string,
    backgroundRemovedFile?: string
}
export interface UploadedMetadata {
    name:string
    uid:string
    url:string
    brand:string
    type?:string
}

export interface Metadata {
    type: ProductType
    x: number
    y: number
    expand?:ExpandMetadata
}

export interface ExpandMetadata {
    posX: number
    posY: number
}

export type Align = "left" | "center" | "right" | undefined;

export interface CanvasObject {
    name?: string // the name of the object that is dragged in.
    thumbnail?:string // the thumbnail of the object that is dragged in.
    type: 'line' | 'text'
    text?:string
    style?:string[],
    alignment?: Align;
    fontFamily?:string
    fontSize?:string   //shoudl just be a number e.g. 140
    color?:string //hexcode
    backgroundColor?:string
    transparent?:boolean
    lineType?:'solid' | 'dot' | 'dashed'
    lineEndType?: string
    lineStartPosition?: {x:number, y:number, z:number}
    lineEndPosition?: {x:number, y:number, z:number}
}


export const MaterialColors = ['White', 'Black', 'Red', 'Green', 'Blue', 'Yellow', 'Cyan', 'Magenta', 'Gray', 'Orange', 'Purple', 'Brown'].sort();
export const MaterialTextures=['Soft','Hard','Matte','Rough','Glossy','Metal','Plastic','Transparent','Normal','Refractive'].sort();

//
// export const createNewMaterial = () : Material => {
//     const m:Material = {id:generateID(), name:'newMaterial',updatedAt:Date.now(), objectStatus:ObjectStatusType.PENDING }
//     return m;
// }

export const ValidateMaterial = (data:any={}) => {    
    let isValid = true;
    const keys = data?.productType=='paint' ? ['id','name','objectStatus'] : ['id','name','files','materialProps', 'objectStatus']
    
    keys.forEach(key => {
        if(!data[key]) {
            isValid = false;
        }
        if (isValid==false) return false;
    });    
    if (!ValidateObjectPending(data['objectStatus'])) return false;
    if (!(data['materialCategory']==null || Array.isArray(data['materialCategory']))) return false;
    if (!(data['materialTextures']==null || Array.isArray(data['materialTextures'])))  return false;
    // if (!(data['dimensions']==null || Array.isArray(data['dimensions'])))  return false;
    if (! ValidateMaterialFiles(data['files'])) return false;    
    if (data.materialType=='paint' && (!data.paintMaterials[0]?.color)) return false;
    return isValid;
}
export const ValidateObjectPending = (value:any) => {
    let isValid=true;
    if (value!='APPROVED' && value!='REJECTED' && value!='PENDING' && value!='NOT_VISIBLE') {
        isValid = false;
    }
    console.log("this is ", isValid);

    return isValid
}
export const ValidateMaterialFiles = (data:any={}) => {
    return data['color_original'] != null;
}
// export interface Material {
//     id: string
//     name: string
//     supplier?: string
//     sourceURL?:string
//     description?:string    
//     updatedAt: number
//     readonly original_name?: string
//     readonly hash?: string   
//     files?: MaterialFiles
//     renders?: Array<string>
//     materialProps?:MaterialProperties
//     dimensions?: Array<number>
//     objectStatus: ObjectStatusType
//     materialImage?:string
//     tags?:Array<string>
//     materialCategory?: Array<string>
//     materialColor?:Array<string>
//     materialTextures?:Array<string>
//     // copyFromID?:string
//     materialType?: 'texture' | 'paint'
// }

// export interface GLTFMesh {
//     category:string
//     id:string
//     name:string
//     isStaticObject:boolean
//     meshTextureRepeat:number
//     materialCategory:Array<string>
//     physicalObject?:PhysicalObjectInterface //used for defined meshes.
//     rotation:Array<number>
//     scale:number
//     supplier?:string
//     tags?:Array<string>
//     updatedAt:number
//     triangles?:number
//     thumbnailSize?:number
//     description?:string    
//     objectStatus:ObjectStatusType
//     version?:number
//     files:GLTFFiles
//     mesh?:GLBMesh
// }


export interface GLTFFiles {
    source_gltf?:string // Source gltf, can be of any size.  Intended to be used in backed high quality rendering.
    gltf_original:string //This gltf is processed to create large and small gltfs.  
    large?:string
    small?:string
    thumbnail?:string
    thumbnail_original:string
    draco_normal?:string  //legacy..
    original_vertices?:number
    source_vertices?:number
    original_size?:number
    source_size?:number
}


// //id,name,?
// export interface GLBMesh {
//     version: number
//     meshType:MeshTypes
//     meshTextureRepeat?:number  //defalut is 1.  Used only is meshType is non_static
//     rotation:Array<number>
//     scale:Array<number>
//     full_sized_glb:string, //full url to glb
//     web_sized_glb:string, //full url to glb
//     web_sized_glb_avif?:string, //avif compressed glb
//     full_sized_vertices:number,
//     web_sized_vertices:number,  //limited to 15000
//     full_file_size:number,  //in bytes
//     web_file_size:number
//     rendered_image:string //url
// }


export interface ProjectData {
    projectId?:string
    userId?:string
    scene?:string
    title?:string
    envmap?:string
    cameraMatrix?:Array<number>
}
export interface ProjectFiles {
    screenshot?:string
}
export interface Project {
    backgroundTexture?:ProductInterface
    id:string
    lighting:Array<number>
    physicalObjects:Array<PhysicalObjectInterface>
    projectData:ProjectData
    files?:ProjectFiles
    cameraPosition?:Array<number>
    controlsTarget?:Array<number>
}

declare global {
    interface Window {
        scene:any;
        simulateDrop:any
        gl:any
        camera:any
        debug:any
        JSZip:any
    }
}

export enum SideBarMode {
    Objects,
    Environment,
    Collections,
    Templates,
    Background,
    Accents,
    Uploads,
    Products,
    YourMaterials,
    Paints,
    Text
}

