
import React, { useMemo,useRef, useState,useEffect } from 'react'
import { CanvasObject, PhysicalObjectInterface, SideBarMode } from '../ts/app_interfaces';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { ScrollableGrid } from './ScrollableGrid';
import { useMattoState } from '../MattoState';
import { commonTypefaces } from '../libs/util';
import { cloudflareCDN } from 'ui';
import { getUrlFromRef } from '../libs/firebase';
import { SearchBox } from '../components/SearchBox';
import { TabButton } from '../buttons/ButtonStyles';
import { create } from 'zustand';

const defaultFont = Object.keys(commonTypefaces).slice(-1)[0]

const templates: Array<CanvasObject> = [
    {type:'text', fontSize:'100', fontFamily:defaultFont, thumbnail:'/images/T.jpg', style:[], text:'Text',  name:'Text', color:'#000', backgroundColor:'#fff', transparent: true },
    {type:'text', fontSize:'100', fontFamily:defaultFont, thumbnail:'/images/Tbold.jpg', text: 'Text', style:['bold'], name:'Text Bold',color:'#000', backgroundColor:'#fff', transparent: true }, 
    {type:'line',name:'Line', lineType:'solid', thumbnail:'/images/line.jpg', lineEndType: 'None' }, 
    {type:'line', name:'Line Dot', lineType:'dot', thumbnail:'/images/line-dot.jpg', lineEndType: 'Dot' },]

type LogoTemplate = {
    type: 'image';
    url: string;
    thumbnail: string;
    name: string;
};

type LogoStore = {
    logos: LogoTemplate[] | null;
    fetchLogos: () => Promise<void>;
};

const useLogoStore = create<LogoStore>((set) => ({
    logos: null,
    fetchLogos: async () => {
        try {
            const response = await fetch('https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/transparent_logos.json');
            const logos = await response.json();
            const templates = logos.map((logo: any) => ({
                type: 'image',
                url: logo.url,
                thumbnail: cloudflareCDN(getUrlFromRef(logo.url), 'width=200,format=auto'),
                name: logo.name,
            }));
            set({ logos: templates });
        } catch (error) {
            console.error('Error fetching logos:', error);
        }
    },
}));

export const TextTab = () => {

    const [tab,setTab] = useState<'labels' | 'logos'>('labels') //labels or curated
    const getName =(template:CanvasObject) =>  tab=='labels' ? template.name : '';
    const getImageSrc = (template:CanvasObject) => template.thumbnail;
    const { logos, fetchLogos } = useLogoStore();
    const [filteredLogoTemplates,setFilteredLogoTemplates] = useState(logos || []);

    useEffect(() => {
        if (!logos) {
            fetchLogos();
            console.log('fetching logos')
        } else {
            setFilteredLogoTemplates(logos);
        }
    }, [logos, fetchLogos]);

    //this gets sent to DropPhysicalObject
    const onDrag = (e, template:CanvasObject) => {
        e.dataTransfer.setData("canvasObject",JSON.stringify(template))
    }
    const onDragLogos = (e, logo:any) => {
        const dynamicMaterialProps = {files:logo.url, 
			repeat:{x:null, y:null}, offset:{x:null, y:null},
			metadata:{name:logo.name, type: 'logo'}}
		e.dataTransfer.setData("logos",JSON.stringify(dynamicMaterialProps))
    }

    const handleSearch = (search:string) => {
        if (!logos) return; 
        const filtered = logos.filter((template) => template.name.toLowerCase().includes(search.toLowerCase()))
        setFilteredLogoTemplates(filtered)
    }
    const handleBlur = (search:string) => {  }

    if ( useMattoState.getState().sideBarMode != SideBarMode.Text)   return (null)
	return (
		<Grid container spacing={1} style={{paddingTop:'17px',paddingLeft:'8px'}}>
            <Grid item xs={12} style={{paddingBottom:'5px',paddingTop:'18px',paddingRight:'15px'}} display='flex' justifyContent="center" >
            <ButtonGroup fullWidth={true} disableElevation variant="text"  >
				<TabButton className={tab=="labels" ? 'selected' : ''} onClick ={ () => setTab('labels') }>Labels</TabButton>
  				<TabButton className={tab=="logos" ? 'selected' : ''} onClick ={ () => setTab('logos') }>Logos</TabButton>
			</ButtonGroup>
            </Grid>

            {tab=='logos' && 
            <Grid item xs={12}  style={{alignItems:'center',padding:'20px 15px 20px 15px', marginLeft:'6px'}}>
                <SearchBox onBlur={handleBlur} onChange={handleSearch} placeholder='Search Logos' />
            </Grid>
            }

			<ScrollableGrid
			    style={{maxHeight:'74vh', overflow:'auto', paddingTop:'10px', paddingLeft:'4px'}}
			    getName={getName}
                getSrc={getImageSrc} 
                draggable={true} 
				onDragStart={tab == 'logos' ? onDragLogos : onDrag}
                loadItems={ ()=> {} }
				items={tab == 'logos' ? filteredLogoTemplates : templates}
                imageStyle={tab == 'logos' ? {backgroundSize: 'contain'} : {}}
            />
		</Grid>
    );
}
