import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Divider, Grid, IconButton, Typography} from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove';
import { Add } from '@mui/icons-material';
import { useRefinementList } from 'react-instantsearch';
import { gtagFacetSearchEvent } from '../libs/util';

interface RefinementProps {
    attribute: string;
    attributeName?: string;
    limit?: number;
    showMore?: boolean;
    disabled?: boolean;
    search?: boolean;
}
export const RefinementDropdown = ({attribute,attributeName='Color',limit,showMore,disabled,search}:RefinementProps) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {  setOpen(!open);  }
    useEffect(() => { if(disabled) setOpen(false) }, [disabled])

    const { items,refine,canRefine, searchForItems,toggleShowMore, canToggleShowMore, isShowingMore} = 
        useRefinementList({ showMoreLimit:1000, attribute: attribute,limit: limit,showMore: showMore,sortBy: ['name:asc'] });

    useEffect(() => {
        if (attribute=='supplier') {
            const urlParams = new URLSearchParams(window.location.search);
            const defaultSupplier = urlParams.get('defaultSupplier')
            if (defaultSupplier && defaultSupplier?.length > 0 && canRefine)  {
                refine(defaultSupplier)
            }
        }
    },[canRefine])

    function toTitleCase(str: string) {
        return str.replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    }

    const [searchValue, setSearchValue] = useState('');

    const refinedItems = items.filter(item => item.isRefined).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    const notRefinedItems = items.filter(item => !item.isRefined).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

    return (
        <Grid container alignItems="center"  >    
            <Grid item xs={10}>
                <Typography style={disabled ? {color:'#cecece'}: {color:'black'}}>{attributeName}</Typography>
            </Grid>
            <Grid item xs={2}  >
                <IconButton onClick={handleClick} size="small" disabled={disabled}> {open ? <RemoveIcon /> : <Add /> } </IconButton>
            </Grid>
            {open==true &&
                <>

                {search==true &&
                    <Grid item xs={12}>
                        <input type="search" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck={false} maxLength={512}
                            onChange={(event) =>  { setSearchValue(event.target.value); searchForItems(event.currentTarget.value)  }   }
                            placeholder={`Search ${attributeName}`}
                            value={searchValue}
                            style={{ paddingLeft:'5px', borderWidth:'1px', borderRadius:'4px', height:'30px', width:'90%', marginTop:'5px', marginBottom:'5px', marginLeft:'9px'}}
                        />
                    </Grid>
                }

                <Grid>                  
                {refinedItems.map( (item,index) =>  {   
                    if (item.label.trim().length == 0) return;
                    return (                
                        <Grid item xs={12} key={index} style={{height:'29px', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                            <Checkbox size='medium' style={{padding: '7px'}} checked={item.isRefined} onChange={() => { refine(item.value);if(!item.isRefined) gtagFacetSearchEvent(attribute,item.value); }} />
                            <Typography variant='caption' style={disabled ? {color:'#cecece'}: {color:'black'}}>{toTitleCase(item.label)} ({item.count})</Typography>
                        </Grid>
                    )
                }
                )}
                </Grid>
                <Grid item xs={12}>
                    {refinedItems.length > 0 && notRefinedItems.length > 0 && <Divider style={{paddingTop:'9px', borderColor: 'rgba(0, 0, 0, 0.5)'}}  />}
                </Grid>
                </>    
            }

            <Grid item xs={12} sx={{maxHeight:'calc(74.5vh - 270px)',  overflowX:'clip', overflowY:'auto'}}>
                {open==true && 
                <>
                    <Grid sx={{marginTop: '0px'}}>
                    {notRefinedItems.map( (item,index) =>  {   
                        if (item.label.trim().length == 0) return;
                        return (                
                            <Grid item xs={12} key={index} style={{height:'29px', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                <Checkbox size='medium' style={{padding: '7px'}} checked={item.isRefined} onChange={() => { refine(item.value);if(!item.isRefined) gtagFacetSearchEvent(attribute,item.value); }} />
                                <Typography variant='caption' style={disabled ? {color:'#cecece'}: {color:'black'}}>{toTitleCase(item.label)} ({item.count})</Typography>
                            </Grid>
                        )
                    }
                    )}
                    </Grid>

                    {showMore==true && canToggleShowMore==true &&                    
                        <Button onClick={toggleShowMore}  > {isShowingMore ? 'Show Less' : 'Show All'} </Button>
                    }
                    
                </>
                }
            </Grid>
            <Grid item xs={12}>
                <Divider style={{paddingTop:'9px'}}  />
            </Grid>
        </Grid>
    )
}
