import React, { useEffect, useState } from 'react';
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import { usePhysicalObjects } from '../MattoState';
import { Slider, Grid, Typography } from '@mui/material';
import { ProductType } from 'ts-interfaces';


export const ExpandVerticalButton = () => {
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const updateObjectState = usePhysicalObjects(state=>state.updateObjectState)
    const [value, setValue] = useState(1);
    const [valueLabelFormat, setValueLabelFormat] = useState("");
    const max = 10;
    const min = 1;
    const step = 1;

    useEffect(() => {
        const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey);
        if(selectedPhysicalObject) {
            setValue(selectedPhysicalObject.metadata?.y ?? 1);
        }
    }, [selectedPhysicalObjectKey]);

    useEffect(() => {
        setValueLabelFormat(value.toString());
    }, [value])

    const handleChange = (event: Event, newValue: any) => {
        const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)
        setValue(newValue);
        if (!selectedPhysicalObject) {
            console.error('No physical object selected');
            return;
        }
        
        if(selectedPhysicalObject.type !== ProductType.EXPANDED) {
            const updatedObject = {
                ...selectedPhysicalObject,
                type: ProductType.EXPANDED,
                metadata: { ...selectedPhysicalObject.metadata, type: selectedPhysicalObject.type, x: 1, y: newValue },
            };
            updateObjectState(updatedObject);
        } else {
            const metadata = selectedPhysicalObject.metadata;
            if(metadata) {
                const updatedObject = {
                    ...selectedPhysicalObject,
                    metadata: { ...selectedPhysicalObject.metadata, y: newValue },
                };
                updateObjectState(updatedObject);
            }
        }
    }

    return (
        <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '5px', maxWidth: '90%', paddingLeft: '21px'}}>
            <Typography variant='caption'>Vertical</Typography>
            <Slider name="vertical-slider" 
                style={{marginRight:'7px'}} 
                size='small' 
                step={step} 
                max={max} 
                min={min} 
                value={value} 
                onChange={handleChange} 
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay='auto'
            />  
        </Grid>
    )
}