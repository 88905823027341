import React, {useMemo,useRef,useEffect,useState,useContext, useCallback} from 'react'
import {ImageItem} from '../libs/ImageItem'
import { unflattenObject, getMaterialThumbnail, handleMeshChange, setProfileFirstAction, gtagViewItemEvent, generateHexCodeArray, generateHCLValues } from '../libs/util'
import { useMattoState, usePhysicalObjects, useProjectData } from '../MattoState'
import { Divider, Grid,Select,MenuItem, Typography,Checkbox, Button } from '@mui/material'
import { SearchFilter } from './SearchFilter'
import { RefinementDropdown} from './RefinementDropdown'
import { CustomSearchBox } from './CustomSearchBox.jsx'
import { FilterButton } from './FilterButton'
import { AuthContext } from "../auth/Auth";
import { ProductType, ProfileFirstActions } from '../../../../packages/ts-interfaces';
import { useInfiniteHits } from 'react-instantsearch'
import { useInView } from 'react-intersection-observer';
import { filterProductToDraggablePaint } from '../libs/product_helpers.js'
import { ActionButton } from '../buttons/ButtonStyles.jsx'
import { useLoginState } from '../studioxr/auth/LoginState.js'
import { getFeedbackForm } from '../libs/util'
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';

export const MaterialHits = ({productType}) => {
    const rootGltfContainer = useRef<any>()
    const [isMyFavorites, setIsMyFavorites] = useState(false)
    const placeholderText = isMyFavorites ? 'Search My Favorites' : 'Search'
	const profile = useLoginState((state) => state.profile);

    return (
        <Grid container ref={rootGltfContainer}  item xs={12} justifyContent="space-evenly" style={{ paddingTop:'10px', paddingLeft:'0px'}}>
        <SearchFilter boundingBox={rootGltfContainer } side='right'>
            <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
                <Grid>
                    <Grid item xs={12} >
                        <CustomSearchBox  placeholderText={placeholderText} />
                        <Grid item xs={12} style={{height:'45px',paddingTop:'9px'}}>
                        <FilterButton productType={productType}  />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}><Divider style={{paddingTop:'9px'}}  /></Grid>
                    <RefinementDropdown showMore={true} limit={15} search={true}  attribute='supplier' attributeName='Brand' />
                    <RefinementDropdown showMore={false} limit={500} attribute='category' attributeName=' Category' />
                    <RefinementDropdown showMore={false} limit={500} attribute='color' attributeName='Color' />
                    {productType != 'paint' && <RefinementDropdown showMore={false} limit={500} attribute='textureType' attributeName='Texture Type'    /> }
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center', paddingTop: '10px', marginTop: 'auto' }}>
                    <ActionButton sx={{ width: '150px' }} onClick={() => window.open(getFeedbackForm(profile))}>
                        <ModeCommentOutlinedIcon sx={{ fontSize: 'medium', marginRight: '4px' }} />
                        <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>Request Brands</Typography>
                    </ActionButton>
                </Grid>
            </Grid>
        </SearchFilter>
        <CustomInfiniteHits   />
        </Grid>
    )
}

const CustomInfiniteHits = () => {
    const {currentUser} = useContext(AuthContext);
    const thumbnailsMaterialContainer = useRef<any>()
    const { hits,isLastPage,showMore} = useInfiniteHits();

	const { ref, inView, entry } = useInView({root:thumbnailsMaterialContainer.current,threshold: 0,});
    useEffect(() => {  
        if (inView==true  && isLastPage==false ) {  
            if (entry && entry?.intersectionRect?.top > 200) {
                showMore()
            }
        } 
    },[inView])

    return (
        <Grid container ref={thumbnailsMaterialContainer}  item xs={12} justifyContent="space-evenly" style={{maxHeight:'calc(100vh - 180px)', overflow:'auto', paddingTop:'10px', paddingLeft:'7px'}}>
            { hits.map( (material, index) => {
                return (
                    <Grid key={index}  item xs={6}  ref={ index === hits.length-1 ? ref : undefined }>
                    <MaterialHit  hit={material}  currentUserUid={currentUser?.uid}/>
                    </Grid>
                )
            })}

        </Grid>
    )
}


export const MaterialHit = ({hit,currentUserUid } ) => {
    const getCurrentThreeJSObject = usePhysicalObjects( (state) => state.getCurrentThreeJSObject)
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
	const setProjectData = useProjectData(state=>state.set)	    
    const getCurrentSelectedPhysicalObject = usePhysicalObjects( (state) => state.getCurrentSelectedPhysicalObject)
    const hitMod  =  unflattenObject(hit)

    const handleMouseOver = (e,id) =>  { 
        e.preventDefault(); 
        const d = document.getElementById("Fav_"+ id);
        //if (d) d.style.visibility = 'visible';    
    }
    const handleMouseOut = (e,id) =>  {  
        e.preventDefault();  
        const d = document.getElementById("Fav_"+ id);
        if (d) d.style.visibility = 'hidden';
    }

    const updateMaterial = (e,material) => {
        gtagViewItemEvent(material)
        const selectedPhysicalObject = getCurrentSelectedPhysicalObject()
        setProfileFirstAction(selectedPhysicalObject == null ? ProfileFirstActions.CHANGE_BACKGROUND :ProfileFirstActions.CLICK_MATERIAL).finally(() => {})
        const idx = selectedPhysicalObject?.type=='paint' ? usePhysicalObjects.getState().selectedMaterialndex : 0 
        handleMeshChange(material,getCurrentThreeJSObject,getCurrentSelectedPhysicalObject,setProjectData,updatePhysicalObject, idx)
    }
    const onDrag = (e,material) => { 
        setProfileFirstAction(ProfileFirstActions.DRAG_MATERIAL).finally(()=>{});
        gtagViewItemEvent(material)
        const firebaseRecord = material;
        const filteredData:any = {}        
        if (firebaseRecord.productType == 'paint'){ 
            const paintObject = filterProductToDraggablePaint(material)            
            e.dataTransfer.setData("paint", JSON.stringify(paintObject)) //this goes to DropPhysicalObject
        }
        else {
            filteredData.materialData={id:null,name:null,files:null,materialProps:null};
            if (firebaseRecord?.materialData?.id) filteredData.materialData.id = firebaseRecord.materialData.id;
            if (firebaseRecord?.id) filteredData.materialData.id = firebaseRecord.id;
            if (firebaseRecord.name) filteredData.materialData.name = firebaseRecord.name;
            if (firebaseRecord.materialData.aspectRatio) filteredData.materialData.aspectRatio = firebaseRecord.materialData.aspectRatio;
            if (firebaseRecord.materialData.files) filteredData.materialData.files = firebaseRecord.materialData.files;
            if (firebaseRecord.materialData.materialProps) filteredData.materialData.materialProps = firebaseRecord.materialData.materialProps;
            if (firebaseRecord.category) filteredData.category = firebaseRecord.category;
            if (firebaseRecord.product_group_id) filteredData.product_group_id = firebaseRecord.product_group_id;
            e.dataTransfer.setData("material", JSON.stringify(filteredData))
        }     
    }

    let materialName = hitMod.name
    if (hitMod?.supplier?.indexOf('BEHR') >= 0) materialName = hitMod.supplier + ' ' + hitMod.name
    else if (hitMod.name.indexOf(' - ') < 1 && hitMod.supplier) {
        materialName = hitMod.supplier  +'-'+ hitMod.name        
    }
    return ( <ImageItem onClick = {(e) => updateMaterial(e,hitMod)} name={materialName} src={getMaterialThumbnail(hitMod)} 
    id={hitMod.id} draggable={true}  type='material' 
    onMouseOver={(e) => handleMouseOver(e,hitMod.id)} onMouseOut={(e) => handleMouseOut(e,hitMod.id)}
     onDragStart={(e)=>onDrag(e,hitMod)} currentUserUid={currentUserUid}/> )
}
