import React, { useState, useContext,useRef, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIconButton from '../buttons/DeleteIconButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ProIconButton } from '../buttons/ProIconButton';
import { BookmarkButton } from '../buttons/BookmarkButton';

const imgCss = { borderRadius:'13px', width:'7vw',height:'7vw', cursor:'pointer',backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat'}
const textCss = {textAlign:'center',textTransform:'capitalize', wordBreak: 'break-word', paddingLeft: '5px', paddingRight: '5px'}
const textTruncate = {overflow: 'hidden',textOverflow: 'ellipsis',display: '-webkit-box',WebkitLineClamp: '2', lineClamp: '2',WebkitBoxOrient: 'vertical'}
const templateCss = { borderRadius:'13px', width:'14vw',height:'14vw', cursor:'pointer',backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat'}

const infoOutline = { color:'rgba(0, 0, 0, 0.54)', 
fontSize:'1.25rem', position: 'absolute', top:'8px', left: '3px', cursor:'pointer' }

export const ImageItemCover = (props) => {
	let currentUserUid = props?.currentUserUid
	const [showIcon, setShowIcon] = useState(false)
	const name = props.name ? props.name : props.getName()
	const src = props.src ? props.src : props.getSrc()
	const [showBookmarkIcon, setShowBookmarkIcon] = useState(false);
	const [showInfoText, setShowInfoText] = useState(false);

	const handleMouseOver = (e) => {
		if (props.showInfoIcon===true) setShowIcon(true)
		if(props.showInfoText===true) setShowInfoText(true)
		props.onMouseOver?.(e)
		setShowBookmarkIcon(true)
	}
	const handleMouseOut = (e) => {
		setShowIcon(false)
		setShowInfoText(false)
		props.onMouseOut?.(e)
		setShowBookmarkIcon(false)
	}
	const userImage = {
		id: props.id,
		name: name,
		src: src,
		files: props?.getImageFiles && props.getImageFiles()
	}
	const userMaterial = {
		id: props.id,
		name: name,
		src: src
	}

	const needsEncoding = (url) => {
		try {
			return decodeURI(url) === url; // If decoding doesn't change it, encoding is needed
		} catch {
			return false; // If decodeURI fails, it's already encoded
		}
	};


	const processUrl = (url) => {
		if (!url) return '';
		url = url.replace(/\(/g, "%28").replace(/\)/g, "%29");
		return needsEncoding(url) ? encodeURI(url) : url; // Encode only if necessary
	};

	const encodedUrl = processUrl(src);

	return (
		<Grid container spacing={0}  >
		   <Grid item xs={12}  style={{display:'flex', justifyContent:'center',position: 'relative', pointerEvents: props?.getIsDisabled && props.getIsDisabled() ? 'None': ''}} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
		   	<div style = {{position: 'relative'}}>
				<div data-testid={name} id={props.id} style={{...(props.type === 'templates'?templateCss:imgCss),backgroundImage:`url("${encodedUrl}")`, ...props.imageStyle}} 
					onClick={props.onClick}  onDragStart={props.onDragStart} draggable={props.draggable}></div>
				{props.onDelete && <CloseIcon /> }
				{(props.type == 'uploads' || props.type == 'myMaterials') &&
						<DeleteIconButton id={props.id} onClick={props.deleteIconOnclick}/>
				}
				{(props.type == 'uploads' || props.type == 'collectionUserImages') &&
						<BookmarkButton userImage={userImage} showBookmarkIcon={showBookmarkIcon} currentUserUid={currentUserUid} />
				}
				{(props.type == 'myMaterials' || props.type == 'collectionUserMaterials') &&
						<BookmarkButton testId={userMaterial.id} userMaterial={userMaterial} showBookmarkIcon={showBookmarkIcon} currentUserUid={currentUserUid} />
				}
			    {showIcon && <InfoOutlinedIcon  onClick={props.onInfoClick}  style={infoOutline} /> }
				{showInfoText && <Typography variant="caption" style={{position: 'absolute', bottom:'5px', left: '0', right: '0', textAlign: 'center', cursor:'pointer', color:'rgba(0, 0, 0, 0.54)'}}>{props.infoText}</Typography>}
				{props?.isPro && <ProIconButton id={props.id} />}
			</div>
		   </Grid>
		 	<Grid container item xs={12} justifyContent="center" >     		 
			 <Typography variant="caption" style={{...textCss,...textTruncate}}>{name}</Typography> 
		 	</Grid>
		</Grid>
	)
}
