import React, { useEffect, useRef, useState, useContext} from 'react'
import {ImageItem} from '../libs/ImageItem'
import {getDocByID, getUrlFromRef} from '../libs/firebase'
import { cloudflareCDN, unflattenObject ,getMaterialThumbnail, gtagViewItemEvent, getProductThumbnail } from '../libs/util'
import {useGLTF} from '@react-three/drei'
import { produce } from 'immer'
import { useInView } from 'react-intersection-observer';
import { handleMeshChange } from '../libs/util'
import {useMattoState,usePhysicalObjects,useProjectData } from '../MattoState'
import { Box, Divider, Grid,Select,MenuItem, Typography,Checkbox } from '@mui/material'
import { CollectionInterface, ObjectStatusType, ProductType, ProfileInterface, UserProfileRole } from 'ts-interfaces'
import { useInfiniteHits } from 'react-instantsearch'
import { useLoginState } from '../studioxr/auth/LoginState'
import { AuthContext } from "../auth/Auth";
import { CollectionUserImages } from '../sidebar/CollectionUserImage'
import { CollectionUserMaterials } from '../sidebar/CollectionUserMaterials'
import { CollectionUserProjects } from '../sidebar/CollectionUserProjects'
import { Stack, styled } from '@mui/system';

export const CollectionHits = (props) => {
    const rootProductContainer = useRef<any>()
    const thumbnailsMaterialContainer = useRef<any>()
	const { ref, inView, entry } = useInView({root:rootProductContainer.current,threshold: 0,});
    const getCurrentThreeJSObject = usePhysicalObjects( (state) => state.getCurrentThreeJSObject)
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
	const setProjectData = useProjectData(state=>state.set)	    
    const getCurrentSelectedPhysicalObject = usePhysicalObjects( (state) => state.getCurrentSelectedPhysicalObject)
    const setCopiedMaterialData = useMattoState((state)=>state.setCopiedMaterialData)
	const copiedMaterialData = useMattoState((state) => state.copiedMaterialData)
    const {currentUser} = useContext(AuthContext);

    const { hits,isLastPage,showMore} = useInfiniteHits();

    useEffect(() => {
        if(hits.length > 0){
            props.getProductsHitsFromTypesenseSearch(hits)
        }
    },[hits])

    useEffect(() => {
        if (inView==true  && isLastPage==false) { showMore() }	
	},[inView])

    const onMaterialClick=(firebaseRecord) => {
        gtagViewItemEvent(firebaseRecord)
        const selectedPhysicalObject = getCurrentSelectedPhysicalObject()
        // if((selectedPhysicalObject?.type == 'paint' &&  firebaseRecord?.productType != 'paint') || ( firebaseRecord?.productType == 'paint' && selectedPhysicalObject?.type != 'paint'))  return;
        const idx = selectedPhysicalObject?.type=='paint' ? usePhysicalObjects.getState().selectedMaterialndex : 0 
        const cloneCopiedMaterialData = {...copiedMaterialData} //this is done just to update the propertyControllerContainer
        setCopiedMaterialData(cloneCopiedMaterialData) //has no relevance with copying materials
	    handleMeshChange(firebaseRecord,getCurrentThreeJSObject,getCurrentSelectedPhysicalObject,setProjectData,updatePhysicalObject,idx)
    
    }
    return (
        <Grid container ref={rootProductContainer}  item xs={12} justifyContent="space-evenly" style={{flex: 1, overflow:'auto', paddingTop:'10px', paddingLeft:'0px'}}>
            <Grid item xs={12} style={{textAlign:'center', marginTop:'-15px'}}> 
            </Grid> 
            <DisplayCollectionTitle collection={props?.selectedCollection} onClose={props?.onClose}  />
            <Grid container ref={thumbnailsMaterialContainer}  item xs={12} justifyContent="space-evenly" style={{paddingTop:'10px', paddingLeft:'7px'}}>
                {props?.selectedCollection?.projects?.length > 0 &&
                        <CollectionUserProjects projects={props.selectedCollection?.projects} currentUserUid={currentUser?.uid} />
                }
                { props?.selectedCollection?.userImages?.length > 0 &&
                        <CollectionUserImages userImages={props.selectedCollection?.userImages} currentUserUid={currentUser?.uid}  collectionType={props?.collectionType} collectionID={props.selectedCollection?.id} collectionName={props.selectedCollection?.name} />
                }
                { props?.selectedCollection?.userMaterials?.length > 0 &&
                        <CollectionUserMaterials userMaterials={props.selectedCollection?.userMaterials} currentUserUid={currentUser?.uid} />
                }
                { hits.map( (product, index) => {
                    return (
                        <Grid key={index} item xs={6}><CollectionHit onClick={onMaterialClick} hit={product} currentUserUid={currentUser?.uid}/></Grid>
                    )
                })}
                <Grid key={'placeholder'} ref={ref} item xs={6}>
                    <img draggable={false} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==" height={1} alt="Red dot" />
                </Grid>
            </Grid>
        </Grid>
    )
};
export const CollectionHit = ({hit, onClick, currentUserUid} ) => {
    const profile: ProfileInterface | any = useLoginState((state) => state.profile);
    const hitMod  = unflattenObject(hit)
	const setShowLogin = useLoginState((state) => state.setShowLogin);
	const setShowSubscription = useMattoState((state) => state.setShowSubscription);

    const handleMouseOver = (e,id) =>  { 
        e.preventDefault(); 
        const d = document.getElementById("Info_"+ id);
        if (d) d.style.visibility = 'visible';    
    }
    const handleMouseOut = (e,id) =>  {  
        e.preventDefault();  
        const d = document.getElementById("Info_"+ id);
        if (d) d.style.visibility = 'hidden';
    }
    useEffect(() => {
        if (window.simulateDrop) return;
            //for testing purposes
        window.simulateDrop = (id=1,offsetX=0,offsetY=0) => {
            const rect = window.gl.domElement.getBoundingClientRect()
            const testEvent :any= new CustomEvent('drop')
            testEvent.dataTransfer = new DataTransfer()			
            testEvent.clientX= rect.x + (rect.width/2) + offsetX
            testEvent.clientY= rect.y + (rect.height/2) + offsetY	
            getDocByID('productsV2',id).then(doc=>{
                if (!doc.exists) {
                    console.log("Could not find mesh with id:",id); return;
                }
                const gltfMesh = doc.data()                
                Object.defineProperty(testEvent, 'target', {writable: false, value: {mesh:doc}});
                onDrag(testEvent, gltfMesh)
                window.gl.domElement.dispatchEvent(testEvent)
            })
            .catch(err => console.log("Error getting record!" + err));     
        }        
    }, [])

    const onDrag = (e,gltf) => {   
        const data = {...gltf};
		if (data.objectStatus == ObjectStatusType.APPROVED_PRO && profile?.userRole !== UserProfileRole.PRO) { if(!profile?.uid) setShowLogin('signup'); else setShowSubscription('startTrial'); return; }
        if(data.productType==ProductType.STATIC) gtagViewItemEvent(data)
        if( gltf?.category != 'Freeform models'){
            data.url = getUrlFromRef(data?.mesh.web_sized_glb)
            if (data.url) useGLTF.preload(getUrlFromRef(data.url,true));		
                const filteredData = produce(data, draft=> {
                    delete draft.triangles; delete draft.compression; delete draft.size_kb; delete draft.thumbnailSize;
            })  
            e.dataTransfer.setData("mesh", JSON.stringify(filteredData))
         }
         else { e.dataTransfer.setData("mesh", JSON.stringify(data)) }
    }  

    const onMaterialDrag = (e,material) => { 
        gtagViewItemEvent(material)
        const firebaseRecord = material;
        const filteredData:any = {}
        filteredData.category=null;
        if (firebaseRecord.productType == 'paint'){ 
            filteredData.paintMaterials = [];
            if (firebaseRecord.materialData.materialProps) filteredData.paintMaterials[0] = firebaseRecord.materialData.materialProps;
            filteredData.paintMaterialsData = [];
            filteredData.paintMaterialsData[0]={id:null,name:null,renderedImage:null};
            if (firebaseRecord?.materialData?.id || firebaseRecord?.id) filteredData.paintMaterialsData[0].id = firebaseRecord.materialData.id || firebaseRecord.id ;
            if (firebaseRecord?.name || firebaseRecord?.materialData?.name) filteredData.paintMaterialsData[0].name = firebaseRecord.name || firebaseRecord.materialData.name;
            if (firebaseRecord?.materialData?.renderedImage) filteredData.paintMaterialsData[0].renderedImage  = firebaseRecord.materialData.renderedImage;       
            // filteredData.category = ['Paint'];
            if (firebaseRecord.category) filteredData.category = firebaseRecord.category;
            filteredData.type ="paint"
            e.dataTransfer.setData("paint", JSON.stringify(filteredData))
        }
        else {
            filteredData.materialData={id:null,name:null,files:null,materialProps:null};
            if (firebaseRecord?.materialData?.id) filteredData.materialData.id = firebaseRecord.materialData.id;
            if (firebaseRecord?.id) filteredData.materialData.id = firebaseRecord.id;
            if (firebaseRecord.name) filteredData.materialData.name = firebaseRecord.name;
            if (firebaseRecord.materialData.files) filteredData.materialData.files = firebaseRecord.materialData.files;
            if (firebaseRecord.materialData.materialProps) filteredData.materialData.materialProps = firebaseRecord.materialData.materialProps;
            if (firebaseRecord.category) filteredData.category = firebaseRecord.category;

            e.dataTransfer.setData("material", JSON.stringify(filteredData))
        }     
    }

    const getImage = (gltf) => {
        if (gltf.mesh?.rendered_image) return cloudflareCDN(gltf.mesh?.rendered_image,'height=120,format=auto')
        if (gltf.files?.thumbnail) { return getUrlFromRef(gltf.files?.thumbnail) }
        if (gltf.mesh?.thumbnail) { return cloudflareCDN(gltf.mesh?.thumbnail,'height=120,format=auto') }
        return undefined;
    }
    let materialName = hitMod.name
    if (hitMod?.supplier?.indexOf('BEHR') >= 0) materialName = hitMod.supplier + ' ' + hitMod.name
    else if (hitMod.name.indexOf(' - ') < 1 && hitMod.supplier) {
        materialName = hitMod.supplier  +'-'+ hitMod.name        
    }
    if(hitMod.productType==ProductType.MATERIAL || hitMod.productType==ProductType.PAINT)
    return ( <ImageItem onClick = { () => onClick(hitMod) } name={materialName} src={getMaterialThumbnail(hitMod)} id={hitMod.id} objectStatus={hitMod.objectStatus} draggable={true} type='collection' onMouseOver={(e) => handleMouseOver(e,hitMod.id)} onMouseOut={(e) => handleMouseOut(e,hitMod.id)} onDragStart={(e)=>onMaterialDrag(e,hitMod)}  currentUserUid={currentUserUid} /> )
    else
    return ( <ImageItem name={hitMod.name} src={getImage(hitMod)} id={hitMod.id} objectStatus={hitMod.objectStatus} onDragStart={(e)=>onDrag(e,hitMod)} draggable={true} type='collection'  currentUserUid={currentUserUid}    /> )
}

const FineText = styled(Typography)(({ theme } ) => ({
    fontSize:'0.75em', fontWeight:'900', color:'black',textOverflow:'ellipsis'
}))
const VeryFineText = styled(Typography)(({ theme } ) => ({
    fontSize:'0.75em', fontWeight:'100', color:'black',textOverflow:'ellipsis'
}))
const getNoOfItems = (coll:CollectionInterface) =>   coll.products.length + (coll.userImages ? coll.userImages.length : 0) + (coll.userMaterials ? coll.userMaterials.length : 0) 

const DisplayCollectionTitle = ({collection, onClose}) => {
    return (
        <Stack sx={{paddingRight:'10px',paddingLeft:'10px', width: '100%'}} >
        <Stack sx={{width:'100%', cursor:'pointer'}}  onClick={onClose} alignContent={'center'}  justifyContent='center' >
            {collection.coverImage?.length > 0 && 
            <Box sx={{
                width: '100%',            // Full width of the container
                paddingTop: '100%',        // 1:1 aspect ratio
                position: 'relative',      // Needed to position the image absolutely
                overflow: 'hidden', 
                borderRadius: '20px'
            }}>
                <img src={cloudflareCDN(collection.coverImage, 'height=400,format=auto')   } style={{
                    // borderRadius:'20px', width:'15vw', height: '15vw', objectFit:'cover', objectPosition: 'center' 
                    position: 'absolute',   // Allows the image to fill the container
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    }} 
                />
            </Box>
            }
            <Stack direction='row' sx={{paddingTop:'5px'}} justifyContent='space-between' alignItems='center' >
                <FineText>{collection.name}</FineText>
                <VeryFineText>{getNoOfItems(collection)} items</VeryFineText>
            </Stack>
            <Stack   >
                <VeryFineText>{collection.description } </VeryFineText>
            </Stack>
        </Stack>    
        </Stack>
    )    
}


