import React, { useEffect, useState, useContext } from "react";
import Header  from './header';
import { AuthContext } from "../auth/Auth";
import { useLoginState } from "./auth/LoginState";
import { getAuth, signInWithEmailLink } from "firebase/auth";
import { getDocs, query, where } from "firebase/firestore";
import { profilesDB } from "../libs/firebase";

export const StudioXRHome = (props) => {
    const {currentUser} = useContext(AuthContext);
    const setShowLogin = useLoginState((state) => state.setShowLogin);

    let url = 'https://blog.mattoboard.com/signup'
    if (window?.location?.pathname) {
        if (window.location.pathname=='/boards') {
            url='https://blog.mattoboard.com/boards'
        }
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

        if(params.mode && params.oobCode) {
            switch (params.mode) {
                case "resetPassword":
                    setShowLogin('reset')
                    break;
                case "signIn":
                    handleEmailVerification();
                    break;
                default:break;
            }
        }

        if ( (!currentUser) && props.startDesigning==true) {
            sessionStorage?.setItem("redirectTo", '/project/new_from?id=1jqh81ou4b&welcome=true');            
            setShowLogin('signup')
        }
    }, [])

    const handleEmailVerification = async () => {
        setShowLogin('verification_screen');
        const auth = getAuth();
        try {
          let email = window.localStorage.getItem('emailForSignIn'); // Retrieve the saved email
          if (!email) {
            const mainParams = new URLSearchParams(window.location.search);
            const continueUrl = mainParams.get("continueUrl");

            if (continueUrl) {
                const decodedContinueUrl = decodeURIComponent(continueUrl);
                const continueParams = new URLSearchParams(new URL(decodedContinueUrl).search);
                email = continueParams.get("email");
            }
         }
          if (email && await signInWithEmailLink(auth, email, window.location.href)) {
            // check if user has an account. If account exists, redirect to project page
            if (profilesDB==null) throw new Error("profilesDB is not defined")
            const q = query(profilesDB, where("email", "==", email));
            const querySnapshot = await getDocs(q);    
            if (querySnapshot.size>0) {
                window.location.href = '/projects'; // redirect to project page
            } else {
                sessionStorage?.setItem("redirectTo", '/project/new_from?id=1jqh81ou4b&welcome=true');            
                setShowLogin('work_login_success');
            }
          } else {
            setShowLogin('verification_failed');
          }
        } catch (error) {
          console.error('Error during email verification/sign-in:', error);
          setShowLogin('verification_failed');
        } finally {
            window.localStorage.removeItem('emailForSignIn');
        }
    };

    return (
        <>
        <Header/>
        <iframe src={url} style={{position:'fixed', top:'0px',
                    border:'0px', left:'0px',height:'100%',width:'100%'}}>
                        Your browser doesn't support iframes
                    </iframe>
        </>
      )
}


