import React from "react";
import { Grid } from "@mui/material";

const VerificationFailed = () => {
  return (
    <Grid style={styles.container}>
      <Grid sx={styles.messageBox}>
        <h1 style={styles.title}>Verification Failed</h1>
        <p style={styles.text}>Please try again or contact studio@mattoboard.com for assistance</p>
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  messageBox: {
    textAlign: "center",
    padding: "20px",
    borderRadius: "8px",
  },
  title: {
    fontSize: "24px",
    color: "#721c24",
    margin: "0",
  },
  text: {
    fontSize: "16px",
    color: "#721c24",
    marginTop: "10px",
  },
};

export default VerificationFailed;
