import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { TextField,FormControlLabel,Checkbox,Box,Typography,IconButton,} from "@mui/material";
import { Visibility as VisibilityIcon,VisibilityOff as VisibilityOffIcon,Google as GoogleIcon,Close as CloseIcon, } from "@mui/icons-material";
import { googleSignup } from "../../libs/firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useLoginState } from "./LoginState";
import { getErrorMessage } from "../helper";
import { PrimaryButton, OutlineButton } from "../Button";
import { SigninContainer } from "./styles";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

export const SignInPage = (props) => {
  return <SignIn {...props} />;
};

export const SignIn = React.forwardRef(({}, ref) => {
  const [showFlash, setShowFlash] = useState(false);
  const [flashMessage, setFlashMessage] = useState("Flash Message");
  const setShowLogin = useLoginState((state) => state.setShowLogin);
  const setOrCreateProfile = useLoginState((state) => state.setOrCreateProfile);
  const [emailValue, setEmailValue] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const setProfile = useLoginState((state) => state.setProfile);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // const history = useHistory();
  const location = useLocation();

  const handleSubmit = () => {
    if(!emailValue || !password){
      setFlashMessage("Please enter all the required details");
      setShowFlash(true);
      return;
    }
    const auth = getAuth();
    signInWithEmailAndPassword(auth, emailValue, password)
      .then((result) => {
        setTimeout(() => {
          setShowLogin(null);
          //history.replace("/")
        }, 100);
      })
      .catch((error) => {
        setShowFlash(true);
        let errMsg = getErrorMessage(error.message)
        errMsg = errMsg + ' - If you need help contact studio@mattoboard.com'
        setFlashMessage(errMsg);
      });
  };
  const handleGoogleSignup = () => {
    googleSignup()
      .then((result:any) => {
        //need to check if profile exists.
        setOrCreateProfile(result, true).then((profile) => {
          setShowLogin(null);          
        })
      })
      .catch(function (error) {
        setShowFlash(true);
        let errMsg = getErrorMessage(error.message)
        errMsg = errMsg + ' - If you need help contact studio@mattoboard.com'
        setFlashMessage(errMsg);
      });
  };
  const onChangeEmail = (e) => {
    setEmailValue(e.target.value.replace(/\s/g, ""));
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <SigninContainer>
      <Box className="form-root">
        <img src={'/images/mattoboard-logo.jpg'} alt="MattoBoard" className="logo-img" />
        <Typography component="h1" variant="h5" className="login-title">
          Sign in To Continue
        </Typography>


        {showFlash && 
        <Typography sx={{fontSize:'1rem'}} component="h6" color='error' align='center' variant="h5">
          {flashMessage}
        </Typography>
        }
         <div className="login-form">
          <TextField
            size='small'
            style={{height:'0.5em',paddingBottom:'15px'}}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="email"
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={emailValue}
            onChange={onChangeEmail}
          />
          <TextField
            size='small'
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleTogglePasswordVisibility}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              ),
            }}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={onChangePassword}
          />

          <Box className="forgot-root">
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
              className="remember-check"
            />
            <Box className="signup-link" sx={{ marginTop: "2px" }}>
              <button
                className="signup-btn"
                style={{color:'blue', fontWeight:'400'}}
                onClick={() => setShowLogin("forgot")}
              >
                Forgot password? Reset
              </button>
            </Box>
          </Box>

          <PrimaryButton fullWidth variant="contained" onClick={handleSubmit}>
            Sign In
          </PrimaryButton>

          <OutlineButton
            onClick={() => {
              handleGoogleSignup();
            }}
            fullWidth
            variant="outlined"
            className="google-btn"
            style={{ marginBottom: "25px" }}
          >
            <GoogleIcon sx={{ color: "#453B41" }} />
            <span>Sign In With Google</span>
          </OutlineButton>
          <Box className="signup-link" sx={{ marginBottom: "10px" }}>
            <button className="signup-btn" onClick={() => setShowLogin("work_login")} style={{fontWeight:'400', marginLeft: '0px'}}>
            <CorporateFareIcon sx={{ marginRight: '3px', verticalAlign: 'sub' }} />Sign in with your work email
            </button>
          </Box>
          <Box className="signup-link">
            <Typography className="signup-text">New user?</Typography>
            <button className="signup-btn" onClick={() => setShowLogin("signup")} style={{color:'blue',fontWeight:'400'}}>
              Create an account
            </button>
          </Box>
        </div>
      </Box>
      <IconButton onClick={() => setShowLogin(null)} className="close-btn">
        <CloseIcon />
      </IconButton>
    </SigninContainer>
  );
});



