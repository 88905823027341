import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import { Button, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import { Person as PersonIcon } from "@mui/icons-material";
import { AuthContext } from "../../auth/Auth";
import { useLoginState } from "./LoginState";
import { getAuth, signOut } from "firebase/auth";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import { Forgot } from "./Forgot";
import AuthModal from "./AuthModal";
import ProfileModal from "./ProfileModal";
import { PersonIconButton } from "./styles";
import { MyProfilePic } from "./MyProfilePic";
import { Reset } from "./Reset";
import {useMattoState} from '../../MattoState'
import { UserProfileRole } from "ts-interfaces";
import { WorkLogin } from "./WorkLogin";
import { VerificationScreen } from "./VerificationScreen";
import VerificationFailed from "./VerificationFailed";

export const LoginModal = () => {
  const setShowLogin = useLoginState((state) => state.setShowLogin);
  const showLogin = useLoginState((state) => state.showLogin);
  const handleClose = () => {
    //if 
    setShowLogin(null);
  } 
  const signupClose = () => {
    //when signing up prevent closing.
    return;
  }


  if (showLogin == "signin") {
    return (
      <AuthModal open={true} onClose={handleClose}>
        <SignIn />
      </AuthModal>
    );
  } else if (showLogin == "signup") {
    return (
      <AuthModal open={true} onClose={signupClose}>
        <>
          {" "}
          <SignUp  />{" "}
        </>
      </AuthModal>
    );
  } else if (showLogin == "forgot") {
    return (
      <AuthModal open={true} onClose={handleClose}>
        <>
          {" "}
          <Forgot />{" "}
        </>
      </AuthModal>
    );
  }  else if (showLogin == "reset") {
    return (
      <AuthModal open={true} onClose={handleClose}>
        <>
          {" "}
          <Reset />{" "}
        </>
      </AuthModal>
    );
  } else if (showLogin == "work_login") {
    return (
      <AuthModal open={true} onClose={handleClose}>
        <>
          {" "}
          <WorkLogin />{" "}
        </>
      </AuthModal>
    );
  } else if (showLogin == "work_login_success") {
    return (
      <AuthModal open={true} onClose={handleClose}>
        <>
          {" "}
          <SignUp activeStep={1} />{" "}
        </>
      </AuthModal>
    );
  } else if (showLogin == "verification_screen") {
    return (
      <AuthModal open={true} onClose={handleClose}>
        <>
          {" "}
          <VerificationScreen />{" "}
        </>
      </AuthModal>
    );
  } else if (showLogin == "verification_failed") {
    return (
      <AuthModal open={true} onClose={handleClose}>
        <>
          {" "}
          <VerificationFailed />{" "}
        </>
      </AuthModal>
    );
  } else return null;
};

export default function AuthButton() {
  const setShowLogin = useLoginState((state) => state.setShowLogin);
  
  const { currentUser }  = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const profile = useLoginState((state) => state.profile);
  const setProfile = useLoginState((state) => state.setProfile);
  const getUserProfile = useLoginState((state) => state.getUserProfile);
  const [profileModalShown, setProfileModalShown] = useState(false);
	const setShowSubscription = useMattoState((state) => state.setShowSubscription);
  const setOrCreateProfile = useLoginState((state) => state.setOrCreateProfile);
  const [attemptedProfile, setAttemptedProfile] = useState(false);  

  useEffect(() => { 
    if (!currentUser?.uid) { setProfile({}); return; }
    loadProfile(); }, 
  [currentUser?.uid]);

  useEffect(() => {
    if (attemptedProfile==false) return;
    setTimeout(() => {
      if (!currentUser?.uid) { setProfile({}); return; }
      if (useLoginState.getState().profile?.uid) return;
      const data = { user: { uid: currentUser?.uid,email: currentUser?.email,} };
      console.log('setOrCreateProfile called from Auth for user:', data.user.email);
      setOrCreateProfile(data);
    },1500)
  },[attemptedProfile])

  const loadProfile = (force=false) =>{
      if (!currentUser?.uid) { setProfile({}); return; }
      if (useLoginState.getState().profile?.uid && force!=true) return;
      const data = { user: { uid: currentUser?.uid,email: currentUser?.email,} };
      getUserProfile(data).then((profile) => { setAttemptedProfile(true); })
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const goToProfile = () => {
    handleClose();
    setProfileModalShown(true);
  };
  const goToProjects = () => { handleClose(); window.location.href = "/projects"; }

  const cancelProSubscription = () => { handleClose(); setShowSubscription('cancel') }
  const goToAccounts = () => { 
    handleClose(); setShowSubscription('accounts') 
  }

  const handleLogout = () => {
    sessionStorage.clear();
    signOut(getAuth()).finally(() => {
      setShowLogin(null);
      handleClose();
    });
  };
  const handleProfileModalSuccess = () => { loadProfile(true); }

  return (
    <>
      {currentUser ? (
        <div>
          <IconButton
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
            size="large"
          >
          <MyProfilePic img={profile?.files?.pic} />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={goToProfile}>Your Profile</MenuItem>
            <MenuItem onClick={goToProjects}>Your Projects</MenuItem>
            <MenuItem onClick={goToAccounts}>Accounts & Billing</MenuItem>

            {/* {profile?.userRole == UserProfileRole.PRO &&
              <MenuItem onClick={cancelProSubscription}>Cancel Mattoboard Pro</MenuItem>
            } */}
            {/* {useStaging &&
              <MenuItem onClick={switchSubscription}>{profile?.userRole == UserProfileRole.PRO ? 'Switch to Non-Pro' : 'Switch to Pro' }</MenuItem>
            } */}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>

          {profileModalShown && (
          <ProfileModal
              open={profileModalShown}
              initialProfile={profile}
              onClose={() => setProfileModalShown(false)}
              onSuccess={handleProfileModalSuccess}
            />
          )}
        </div>
      ) : (
        <>
          <PersonIconButton edge="start" onClick={() => setShowLogin("signup")}>
            <PersonIcon />
          </PersonIconButton>
        </>
      )}
      <LoginModal />
    </>
  );
}
