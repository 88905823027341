import React, { useMemo,useRef, useState,useEffect } from 'react'
import { Fluorescent as Transmission, Flare as FlareIcon, TrendingFlat as DisplaceIcon ,Repeat as RepeatIcon, Texture as TextureIcon,Brightness7 as BrightnessIcon,Backspace,ColorLens,Save} from '@mui/icons-material';
import {useMattoState,usePhysicalObjects,useProjectData} from '../MattoState'
import { Button, Grid, IconButton, Slider, Tooltip, Typography,Divider, fabClasses } from '@mui/material';
import { ImageItem } from '../libs/ImageItem';
import { deepClone,dimensionsToString,materialDefaults,getDimensions, calculateRepeatModifier,getMaterialThumbnail, getMaterialThumbnailFromMaterialData } from '../libs/util';
import { produce } from 'immer'
import { invalidate } from '@react-three/fiber';
import { styled } from '@mui/system';
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import { Color,MathUtils } from 'three';
import { ScaleRotationSelector } from './ScaleRotationSelector';
import { ColorHeader, ColorSelector } from './ColorSelector';
import { ArrowKeys } from './ArrowKeys';
import FormatPaintRoundedIcon from '@mui/icons-material/FormatPaintRounded';
import { ObjectStatusType, ProductInterface, ProductType } from 'ts-interfaces';
import { PropertyModifier2 } from './PropertyModifier2';
import { Variants } from '../components/Variants';
import { PaintMaterialSelect } from '../instasearch/PaintMaterialSelect';
import { ProductDownloadButton } from '../buttons/ProductDownloadButton';
import { ExpandHorizontalButton } from '../buttons/ExpandHorizontalButton';
import { ExpandVerticalButton } from '../buttons/ExpandVerticalButton';
import { MaterialVariants } from '../components/MaterialVariants';

const text={paddingTop:'20px',textAlign:'center',textTransform:'capitalize',overflow:'hidden', textOverflow:'ellipsis'} as any
const text2={paddingTop:'0px',textAlign:'center',textTransform:'capitalize',overflow:'hidden', textOverflow:'ellipsis'} as any

export const PropertyControllerContainer = () => {
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const backgroundTexture:ProductInterface | any = useProjectData(state=>state.backgroundTexture)
    const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)

    const objectIsSelected = selectedPhysicalObjectKey ? true : false

    const hasPresets = () => {
        const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)
    
        if (selectedPhysicalObject==null && backgroundTexture?.materialData?.preset) return true;        
        else {
            const idx = usePhysicalObjects.getState().selectedMaterialndex || 0
            const paintMaterialData:any = selectedPhysicalObject?.paintMaterialsData?.[idx]
            // if (paintMaterialData?.objectStatus==ObjectStatusType.USER_CREATED) return false;    
            if (!paintMaterialData) return false;
            // else if (paintMaterialData.userCreated==true) return false;
            else return paintMaterialData.preset ? true : false
        }
    }
    const isObjectEditable = () => {
        if (selectedPhysicalObject==null && backgroundTexture?.productType == ProductType.PAINT) return false; //is background object        
        else if (!selectedPhysicalObject) return true;
        else if ( selectedPhysicalObject.isStaticObject===true || selectedPhysicalObject.type=='paint' ) return false;
        else if (usePhysicalObjects.getState().isSelectedDynamicMaterial()===true) return false
        else if (selectedPhysicalObject.isStaticObject==false 
                    || selectedPhysicalObject.type=='not_static' 
                    || selectedPhysicalObject.type=='Shape') return true
        else return false;
    }

    const isObjectDownloadable = () => {
        if (selectedPhysicalObject?.isStaticObject == true && selectedPhysicalObject?.type == 'gltf') return true;
        else return false;
    }

    const isObjectExpandable = () => {
        if (selectedPhysicalObject?.metadata?.expand) return true;
        if (selectedPhysicalObject?.type == ProductType.EXPANDED) return true;
        else return false;
    }

    const showPaintProperty = () => {
        if (selectedPhysicalObjectKey==null) return backgroundTexture?.productType == ProductType.PAINT;
        else { return getSelectedPhysicalObject(selectedPhysicalObjectKey)?.type == 'paint' }
    }

    const hasMaterialVariants = () => {
        const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)
        if (selectedPhysicalObject==null) return false;
        if (!selectedPhysicalObject.materialData) return false;
        if (selectedPhysicalObject?.product_group_id && selectedPhysicalObject?.product_group_id != selectedPhysicalObject?.materialData?.id) return true;
        else return false;
    }

    return (
        <>
		<Grid container item xs={12} id = 'PropertyControllerContainer' justifyContent="space-evenly" sx={{paddingRight:{md:'10px',xl:'0px'}}} style={{maxHeight:'79vh', overflowY:'auto',overflowX:'hidden', paddingTop:'10px'}}>
            {  showPaintProperty()  && <PaintMaterialProperty />}
            {/* {  (material_colorIsEditable || isShapeshift) && <PropertyControllerContainer2 />} */}
            
            {isObjectEditable() && <PropertyModifier2 /> }
            { hasPresets() && <Variants />}
            { hasMaterialVariants() && <MaterialVariants />}
            { isObjectEditable() && <ColorBlock />}
            { isObjectDownloadable() && <ProductDownloadButton />}

            { isObjectExpandable() &&
                <Grid sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px' }}>
                    <Typography style={{ textTransform: 'capitalize', paddingLeft: '4px' }}>Expand Object</Typography>
                    <ExpandHorizontalButton />
                    <ExpandVerticalButton />
                </Grid>
            }

            { !selectedPhysicalObjectKey && !backgroundTexture?.materialData?.preset && <div style={{ height: "200px"}}>&nbsp;</div> }
            {/* {  material_colorIsEditable && !objectIsSelected && 
                <div style={{ height: "250px"}}>&nbsp;</div>
            } */}
            {/* {isShapeshift && <ProductMetaData /> } */}
            {objectIsSelected && <ScaleRotationSelector /> }
            {objectIsSelected && <ArrowKeys /> }
        </Grid>
        </>
    )
}

const ColorBlock = () => {
        return (
            <Grid container id = 'colorBlock' style={{display: "flex"}}>
                <Grid item xs={12} style={{paddingBottom:'2px'}} ><Divider /></Grid>
                <ColorHeader />
                <ColorSelector field='color' name='Texture Color' defaultColor='#ffffff' handleColorChangeOnMouseUpFunc={null} paintSearchCode={null} />
                <ColorSelector field='emissive' name='Emissive' defaultColor='#000000' handleColorChangeOnMouseUpFunc={null} paintSearchCode={null} />
            </Grid>
        )
}


export const PropertyControllerContainer2 = () => {
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getCurrentThreeJSObject = usePhysicalObjects( (state) => state.getCurrentThreeJSObject)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)

    const [selectorProperty, setSelectorProperty] = useState('Roughness')
    const [value,setValue]=useState(0.5)
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
	const setProjectData = useProjectData(state=>state.set)	
    const [selectorRange, setSelectorRange]=useState({max:1,min:0})
    const isSelectedObjectStatic = usePhysicalObjects(state=>state.isSelectedObjectStatic)    
    const backgroundTexture:ProductInterface | any = useProjectData(state=>state.backgroundTexture)
    const setCopiedMaterialData = useMattoState((state)=>state.setCopiedMaterialData)
	const copiedMaterialData = useMattoState((state) => state.copiedMaterialData)
    const [clearMaterialThumbnail, setclearMaterialThumbnail] = useState(false)
    
    useEffect(() => {
        handleCopyMaterialData()

        if (selectedPhysicalObjectKey==null && backgroundTexture==null) return;

        if (selectorProperty=='Roughness') {
            setSelectorRange({max:1,min:0})
            setValue(getCurrentMaterialValue('roughness') ?? 0.5)
        }
        else if (selectorProperty=='Metalness') {
            setSelectorRange({max:1,min:0})
            setValue(getCurrentMaterialValue('metalness') ?? 0.5)
        }
        else if (selectorProperty=='Transmission') {
            setSelectorRange({max:1,min:0})
            setValue(getCurrentMaterialValue('transmission') ?? 0.5)
        }
        else if (selectorProperty=='Repeat') {
            setSelectorRange({max:selectedPhysicalObjectKey==null  ? 20 : 5,min:0})
            const v = getCurrentMaterialValue('textureRepeat')
            setValue(getCurrentMaterialValue('textureRepeat') || (selectedPhysicalObjectKey==null ? 15 : 1) )
        }
        else if (selectorProperty=='Exposure') {
            setSelectorRange({max:4,min:0})
            setValue(getCurrentMaterialValue('envMapIntensity') ?? 0.5)
        }
        else if (selectorProperty=='Displacement') {
            setSelectorRange({max:2,min:0})
            setValue(getCurrentMaterialValue('displacementScale') ?? 0.0)
        }
    },[selectorProperty,selectedPhysicalObjectKey,backgroundTexture,clearMaterialThumbnail,copiedMaterialData])

    const handleCopyMaterialData=() => {
		if((!document.getElementById("root")?.classList.contains("dropper-cursor"))|| selectedPhysicalObjectKey!= null)
		return;
		else{
			document.getElementById("root")?.classList.remove("dropper-cursor")
		}
	}
    const getCurrentMaterialValue = (type) => {
        if (selectedPhysicalObjectKey!=null) {
            const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)     
            return selectedPhysicalObject?.materialData?.materialProps?.[type]
        } 
        else if (backgroundTexture!=null) return backgroundTexture?.materialData?.materialProps?.[type]
        else return null;
    }
    const updateMaterialProp = (prop,value) => {
        if (selectedPhysicalObjectKey==null) {
            const newObj = produce(backgroundTexture, (draft:any) => { draft.materialData.materialProps[prop] = value })
            setProjectData(state=> { state.backgroundTexture = newObj })
        }
        else {
            const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)        
            const newObj = produce(selectedPhysicalObject, (draft:any) => { draft.materialData.materialProps[prop]=value })
            updatePhysicalObject(newObj)
        }        
    }       

    const setRepeatValue = (mesh, selectedPhysicalObjectKey, textureRepeat=1) => {
        if (!mesh.material.map) return ;
        let textureRepeatModifier = calculateRepeatModifier(getSelectedPhysicalObject(selectedPhysicalObjectKey))
        textureRepeatModifier = textureRepeatModifier == 0 || isNaN(textureRepeatModifier) ? 1 : textureRepeatModifier	
        const v = textureRepeat * (textureRepeatModifier ?? 1)        
        mesh.material.map.repeat.set(v,v)
    }

    const handleSliderChange = (event, newValue) => {
        setValue(newValue)
        if (selectedPhysicalObjectKey==null && backgroundTexture==null) return;
        const threeObj = selectedPhysicalObjectKey!=null ? getCurrentThreeJSObject()
            : window.scene.children.filter(c => c.name=='floor' )[0]

        if (threeObj.isMesh) {
            switch (selectorProperty) {
                case 'Roughness': threeObj.material.roughness = newValue; break;
                case 'Metalness': threeObj.material.metalness = newValue; break;
                case 'Transmission': threeObj.material.transmission = newValue; break;
                case 'Exposure': threeObj.material.envMapIntensity = newValue; break;
                case 'Displacement': threeObj.material.displacementScale = newValue; break;
                case 'Repeat': setRepeatValue(threeObj, selectedPhysicalObjectKey, newValue); break;
                // case 'Repeat': threeObj.material.map && threeObj.material.map.repeat.set(newValue,newValue); break;
                default: return null;
            }
        }
        else {
            threeObj.traverse(child => {
                if (child.isMesh) {
                    switch (selectorProperty) {
                        case 'Roughness': child.material.roughness = newValue; break;
                        case 'Metalness': child.material.metalness = newValue; break;
                        case 'Transmission': child.material.transmission = newValue; break;
                        case 'Exposure': child.material.envMapIntensity = newValue; break;
                        case 'Displacement': child.material.displacementScale = newValue; break;
                        case 'Repeat': setRepeatValue(child, selectedPhysicalObjectKey, newValue); break;
                        // case 'Repeat': child.material.map && child.material.map.repeat.set(newValue,newValue); break;
                        default: return null;
                    }
                }
            })
        }
        invalidate()
    }
    const handleSliderChangeCommited = (event, newValue) => {
        setValue(newValue)
        if (selectedPhysicalObjectKey==null && backgroundTexture==null) return;
        switch (selectorProperty) {
            case 'Roughness': updateMaterialProp('roughness',newValue); break;
            case 'Metalness': updateMaterialProp('metalness',newValue); break;
            case 'Transmission': updateMaterialProp('transmission',newValue); break;
            case 'Exposure': updateMaterialProp('envMapIntensity',newValue); break;
            case 'Repeat': updateMaterialProp('textureRepeat',newValue); break;
            case 'Displacement': updateMaterialProp('displacementScale',newValue); break;
            default: return null;        
        }        
    }

    const clearMaterial = () => {
        if (selectedPhysicalObjectKey==null) {
            setProjectData(state=> { state.backgroundTexture = null })
        }
        else {
            const materialProps = deepClone(materialDefaults)      
            if (isSelectedObjectStatic()===false) {
                materialProps.color = new Color(0xC5C5C5)
                materialProps.color =  materialProps.color.toJSON();
            }
            const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)        
            const newObj = produce(selectedPhysicalObject, (draft:any) => { 
                draft.materialData.materialProps=materialProps
                draft.materialData.files=null
            })				
            updatePhysicalObject(newObj)
            setclearMaterialThumbnail(!clearMaterialThumbnail)
        }
    }   
    const colorPicker = () => {
        const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)   
        document.getElementById("root")?.classList.add("dropper-cursor")
        const toCopyMaterialData = selectedPhysicalObject?.name ? selectedPhysicalObject : backgroundTexture ;
        setCopiedMaterialData(toCopyMaterialData)
    }; 
    return (
    <Grid container id = 'PropertyControllerContainer2'>
    {/* <Grid container style={{height:'44px', overflow:'hidden'}} >
        <Grid item xs={12} sx={{ maxHeight: (theme:any) => theme.bar.height }}
        style={{display:'flex', alignItems:'center',justifyContent:'center', paddingTop:'10px'}}> 
            <Typography style={{textOverflow:'ellipsis', textAlign:'center',fontSize:'0.95em'}}>{headerText}</Typography>
        </Grid>
    </Grid>
    <Grid item xs={12} sx={{ maxHeight: (theme:any) => theme.bar.height }} >
    <Divider />
    </Grid> */}
{/* //sx={{ maxHeight: (theme:any) => theme.bar.height }}  */}
    <Grid container style={{overflow:'hidden'}}>
    <Grid id= 'ColorPickerIcon' item xs={12} style={{display:'flex',justifyContent:'center'}}>
        <TooltipIcon id= 'ColorPicker' style={{paddingTop: '0px'}} title={'CopyFormat'} handleClick={ ()=> { colorPicker() }} >
            <FormatPaintRoundedIcon />
        </TooltipIcon>
    </Grid>
    <Grid container item xs={12} style={{paddingTop:'0px'}} > 
        <Grid item xs={2} style={{paddingLeft:'10px'}} >
        <TooltipIcon title={'Clear'} handleClick={ ()=> { clearMaterial() }} >
                <Backspace />
            </TooltipIcon>
            <TooltipIcon title={'Transmission'} handleClick={()=> {setSelectorProperty('Transmission')}} >
                <Transmission />
            </TooltipIcon>
            <TooltipIcon title={'Exposure'} handleClick={()=> {setSelectorProperty('Exposure')}} >
                <FlareIcon />
            </TooltipIcon>
            {/* <TooltipIcon title={'Displacement'} handleClick={()=> {setSelectorProperty('Displacement')}} >
                <DisplaceIcon />
            </TooltipIcon> */}
        </Grid>
        <Grid item xs={8} >
            <MaterialToShow selectedPhysicalObjectKey={selectedPhysicalObjectKey}  />
        </Grid>
        <Grid item xs={2} >
            <TooltipIcon title={'Repeat'} handleClick={ ()=> { setSelectorProperty('Repeat')}} >
                <RepeatIcon />
            </TooltipIcon>
            <TooltipIcon title={'Roughness'} handleClick={()=> {setSelectorProperty('Roughness')}} >
                <TextureIcon />
            </TooltipIcon>
            <TooltipIcon title={'Metalness'} handleClick={()=> {setSelectorProperty('Metalness')}} >
                <BrightnessIcon />
            </TooltipIcon>
        </Grid>
    </Grid>
    
    {isSelectedObjectStatic()==false &&
    <Grid container item xs={12} style={{maxWidth:'90%',paddingLeft:'30px'}} >
        <Typography style={text2} variant="caption" >{selectorProperty} </Typography> 
        <Slider id='propSlider' size='small' step={0.01} max={selectorRange.max} min={selectorRange.min} value={value} onChange={handleSliderChange} onChangeCommitted={handleSliderChangeCommited} valueLabelDisplay="auto"   />        
    </Grid>
    }   
    </Grid>
    </Grid>
    )
}
const getSelectedMaterialThumbnail = (materialData) =>{
    if(materialData?.files || materialData?.renderedImage )
    return getMaterialThumbnailFromMaterialData(materialData) 
    else
    return false
}

function createImageFromColor(hex)  {
    //convert hex int to hexcode
    hex = hex.toString(16);
    hex = "#" + hex;    
    const canvas = document.createElement('canvas');
    canvas.width = 2;
    canvas.height = 2;
    const context:any = canvas.getContext('2d');
    context.fillStyle = hex;
    context.fillRect(0, 0, canvas.width, canvas.height);
    return canvas.toDataURL();
}

const MaterialToShow = ({selectedPhysicalObjectKey}) => {
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)
    const idx = usePhysicalObjects( (state) => state.selectedMaterialndex)
    const materialData = selectedPhysicalObject?.type=='paint' ? selectedPhysicalObject?.paintMaterialsData?.[idx] : selectedPhysicalObject?.materialData
    const projectData = useProjectData()
    let thumbnail = selectedPhysicalObject?.name ? getSelectedMaterialThumbnail(materialData): projectData.backgroundTexture ? getMaterialThumbnail(projectData.backgroundTexture):false
    const materialName = selectedPhysicalObject?.name ? materialData?.name : projectData?.backgroundTexture?.['name']

    if ( materialData?.userCreated===true && selectedPhysicalObject?.paintMaterials?.[idx]) {
       thumbnail =  createImageFromColor(selectedPhysicalObject.paintMaterials[idx].color)
    }
    // const materialId = selectedPhysicalObject?.name ? selectedPhysicalObject?.materialData?.id : projectData?.backgroundTexture?.id
    if (thumbnail) {
        return  <ImageItem
        name={materialName} 
        draggable={false}  
        src={thumbnail} 
        hideBookmarkIcon={true}
        // id={materialId}
        // type='selectedMaterial'
        // visible='true'
         />
    }
    else {
        return <Typography  style={text}></Typography>
    }
}
const PaintMaterialProperty = () => {
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    
    // do not delete - need this to update the material thumbnail
    const selectedPhysicalObject = usePhysicalObjects(state=>state.selectedPhysicalObject)

    return (
        <Grid container id = 'PaintMaterialProperty'>
        <PaintMaterialSelect  />
        <Grid container item xs={12} style={{display:'flex',justifyContent:'center',paddingTop:'15px',paddingBottom:'15px'}} >         
            <MaterialToShow selectedPhysicalObjectKey={selectedPhysicalObjectKey} />
        </Grid>  
        <Grid item xs={12} style={{paddingBottom:'2px'}} ><Divider /></Grid>
        </Grid>
    )
}
const TooltipIcon = (props) => {
    return (        
        <Tooltip style={props.style} title={props.title} arrow>
		<IconButton onClick={props.handleClick} edge='start' size='small'>
		{props.children}
		</IconButton>
		</Tooltip>
    )
}
