import React, { useEffect, useState } from 'react';
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import {usePhysicalObjects, useProjectData} from '../MattoState'
import { getDocs, query, where } from 'firebase/firestore';
import { productsDB } from '../libs/firebase';
import { Button, Grid, Tooltip, Typography, Stack } from "@mui/material";
import { getMaterialThumbnail, handleMeshChange } from '../libs/util';

const imgCss = { borderRadius:'8px', width:'4vw',height:'4vw', cursor:'grab',backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat'}

export const MaterialVariants = () => {
    const selectedPhysicalObject:(PhysicalObjectInterface | null | any)  = usePhysicalObjects(state=>state.selectedPhysicalObject)
    const [variants, setVariants] = useState<any>([])
    const [processing, setProcessing] = useState(false)
    const [showMore, setShowMore] = useState(false); // State to control showing more or less variants
    const getCurrentThreeJSObject = usePhysicalObjects( (state) => state.getCurrentThreeJSObject)
    const getCurrentSelectedPhysicalObject = usePhysicalObjects( (state) => state.getCurrentSelectedPhysicalObject)
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
    const setProjectData = useProjectData(state=>state.set)	    

    const getProductsByGroup = async (productGroupId) => {
        if(productsDB == null) return;
        const q = query(productsDB, where("product_group_id", "==", productGroupId), where("objectStatus", "==", "APPROVED"));
        const querySnapshot = await getDocs(q);
        const products = querySnapshot.docs.map(doc => doc.data() as any);
        return products;
    };
    
    useEffect(() => {
        if(selectedPhysicalObject==null || processing) return;
        fetchProduct()
        async function fetchProduct() {
            setProcessing(true)
            const products = await getProductsByGroup(selectedPhysicalObject?.product_group_id);
            setVariants(products)
            setProcessing(false)
        }
    },[selectedPhysicalObject]);

    const handleVariantClick = (variant) => {
        const idx = 0;
        handleMeshChange(variant,getCurrentThreeJSObject,getCurrentSelectedPhysicalObject,setProjectData,updatePhysicalObject, idx)
    }

    const visibleVariants = showMore ? variants : variants.slice(0, 6); // Control the number of visible variants

    return (
        <Grid container style={{ width: '100%' }}>
            <Grid item xs={12}>
                <Typography style={{ paddingLeft: '4px', paddingTop: '15px' }}>Variants</Typography>
            </Grid>
            <Grid item>
                <Grid container spacing={2} sx={{ padding: '4px', paddingTop: '8px', paddingBottom: '8px' }}>
                    {visibleVariants.map((variant) => (
                        <Grid item xs={4} key={variant.id}>
                            <Tooltip title={variant.name} placement="top">
                                <div className="image-item"
                                    id={variant.id}
                                    data-testid="variant-item"
                                    style={{ ...imgCss, backgroundImage: 'url("' + getMaterialThumbnail(variant) + '")' }}
                                    onClick={() => handleVariantClick(variant)} ></div>
                            </Tooltip>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            {variants.length > 6 && (
                <Button sx={{ width: '100%' }} onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Less...' : 'More...'}
                </Button>
            )}
        </Grid>
    );

}