import React, { useState, useEffect, useMemo } from "react";
import { TextField, Grid, Typography, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useLoginState } from "./LoginState";
import { PrimaryButton } from "../Button";
import { SigninContainer } from "./styles";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

export const WorkLogin = () => {
  const [emailValue, setEmailValue] = useState<string>("");
  const [showFlash, setShowFlash] = useState<boolean>(false);
  const [flashMessage, setFlashMessage] = useState<string>("");
  const [disableContinue, setDisableContinue] = useState<boolean>(false);

  const setShowLogin = useLoginState((state) => state.setShowLogin);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendLoginLink = async () => {
    const redirectUrl = `${window.location.origin}/verify?email=${encodeURIComponent(emailValue)}`;

    const actionCodeSettings = {
      url: redirectUrl,
      handleCodeInApp: true
    };

    const auth = getAuth();
    try {
        await sendSignInLinkToEmail(auth, emailValue, actionCodeSettings);
        window.localStorage.setItem("emailForSignIn", emailValue);
        setShowFlash(true);
        setFlashMessage("Login link sent to your email. Please check your inbox.");
    } catch (error) {
        console.error("Error sending email link:", error);
        setShowFlash(true);
        setFlashMessage("Failed to send login link. Please try again.");
        setDisableContinue(false);
    }
  };

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    setShowFlash(false);
    if(!emailValue){
      setFlashMessage("Please enter your work email");
      setShowFlash(true);
      return;
    }
    if (!validateEmail(emailValue)) {
      setFlashMessage("Invalid email address");
      setShowFlash(true);
        return;
    }
    setDisableContinue(true);
    sendLoginLink();
  };

  const onChangeEmail = (e) => {
    setFlashMessage("");
    setEmailValue(e.target.value.replace(/\s/g, ""));
  };

  return (
    <SigninContainer>
      <Grid className="form-root">
        <img src={'/images/mattoboard-logo.jpg'} alt="MattoBoard" className="logo-img" />
        <Typography component="h1" variant="h5" className="login-title">
            Continue with your work email
        </Typography>
        {showFlash && 
        <Typography component="h6" color='error'>
          {flashMessage}
        </Typography>
        }
        <div className="login-form">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="email"
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={emailValue}
            onChange={onChangeEmail}
          />

          <PrimaryButton
            fullWidth
            style={{ marginTop: "38px" }}
            variant="contained"
            onClick={handleSubmit}
            disabled={disableContinue}
          >
            Continue
          </PrimaryButton>
        </div>
      </Grid>
      <IconButton onClick={() => setShowLogin(null)} className="close-btn">
        <CloseIcon />
      </IconButton>
    </SigninContainer>
  );
};
