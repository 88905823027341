import React, { useRef,useState,useEffect, useContext } from 'react'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import {Backdrop,CircularProgress,Button,DialogContent,DialogActions,Dialog,DialogTitle,IconButton,Tooltip,Grid, Select, MenuItem, InputLabel, Typography} from '@mui/material'
import {takeScreenshot, takeScreenshotTransparent} from '../libs/screenshot'
import { useProjectData, usePhysicalObjects } from '../MattoState'
import { styled } from '@mui/system';
import { CropImageAspect } from '../libs/DownloadCropImage/cropImageAspect.tsx'
import { ScrollableGrid } from '../sidebar/ScrollableGrid';
import { UserProfileRole } from 'ts-interfaces';
import { useLoginState } from "../studioxr/auth/LoginState";
import { DeepRenderButton } from '../deep_render/DeepRenderButton.tsx';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {BarIconButton} from './ButtonStyles';

const DownloadBackdrop = styled(Backdrop)(({ theme }) => ({ 
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
}))

const formatTypes=['JPG','PNG','WEBP']
const backgroundTypes=['Normal','Transparent']

export function DownloadButton(props) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [aspect,setAspect]=useState('original')
    const setSelectedPhysicalObjectKey = usePhysicalObjects( (state) => state.setSelectedPhysicalObjectKey)

    const [previewOpen, setPreviewOpen]=useState(false)
    const handleClick = (event) => {
        setSelectedPhysicalObjectKey(null)
        setAnchorEl(event.currentTarget);
        setPreviewOpen(true)
    }
    const handleAspect = (event,newMode) => { 
        setAspect(newMode) 
    }
    return (
        <React.Fragment>
        <Tooltip title="Download" arrow>
        <BarIconButton
            onClick={handleClick}
            aria-label="downloadBtn"
            edge='start'
            size="large">
            <FileDownloadOutlinedIcon style={{color:'black'}} />
        </BarIconButton>    
        </Tooltip>
        {previewOpen && <PreviewDialog open={previewOpen} onClose={ () => { setPreviewOpen(false) } }  /> }
        </React.Fragment>
    );
}

const PreviewDialog = ({open,onClose}) => {
    const profile = useLoginState((state) => state.profile);
    const [loading,setLoading]=useState(true)
    const [url, setUrl]=useState(null)
    const projectData = useProjectData()
    const [format, setFormat] = useState('JPG')
    const [backgroundType, setBackgroundType] = useState('Normal')
    const [cropAspect, setCropAspect] = useState(null)
    const [cropDefinition, setCropDefinition] = useState(null)

    const [downloadClicked, setDownloadClicked]=useState(false)

    const [cropImages, setCropImages]= useState([
        {name:'Original',value:null,src:null,isPro:false},
        {name:'Square(1:1)',value:1 / 1,src:null,isPro:true},
        {name:'Potrait(3:4)',value:3 / 4,src:null,isPro:true},
        {name:'Story(9:16)',value:9 / 16,src:null,isPro:true},
        {name:'Landscape(4:3)',value:4 / 3,src:null,isPro:true},
        {name:'Widescreen(16:9)',value:16 / 9,src:null,isPro:true}])

    const proOptions = ['PNG','WEBP','Transparent']
    
    const fileName = projectData.title.replace(/[^a-z0-9]/gi, '_').toLowerCase();

    const handleOpen = () => { }
    const handleClose = () => { setUrl(null); onClose() };

    useEffect(() => {
        const timer = setTimeout(() => {    
            const imageType = format == 'JPG' ? 'image/jpeg' : format == 'PNG' ? 'image/png' :  format == 'WEBP' ? 'image/webp' : 'image/'+ format
            const s = backgroundType == 'Transparent' ? takeScreenshotTransparent() : takeScreenshot(3,0.9,false,imageType)
            setUrl(s)
            setLoading(false)
        },100)
        return () => clearTimeout(timer);

    },[backgroundType,format])

    const download = () => {
        if (window.gtag)  window.gtag('event','download_project') 
        if(cropAspect && cropAspect > 0 ) {  setDownloadClicked(true); return;}
        var downloadLink = document.createElement('a');
        downloadLink.target = '_blank'
        downloadLink.href = url
        downloadLink.download = fileName + '.' + format
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink)
        if(downloadClicked) setDownloadClicked(false)
    }

    const getName =(cropImage)=>{ return cropImage.name }
    const getImageSrc = (cropImage) => { return cropImage.src; }
    const getIsDisabled = (cropImage)=>{ return cropImage.isPro && profile?.userRole !== UserProfileRole.PRO   }
    const handleCropImagesClick = (cropImage) => {  setCropAspect(cropImage.value) }
    const isProOption = (option) => { return  proOptions.includes(option) }
    const disableOption = (option) =>{ return profile?.userRole !== UserProfileRole.PRO && proOptions.includes(option) }
     
    const createThumbailForAllAspect = () => {
        const updatedcropImages = cropImages.map(cropImage => {
            const thumbnailUrl = cropImage.value ? getAspectThumbnail(cropImage.value) : url;
            return { ...cropImage, src: thumbnailUrl };
        });
        setCropImages(updatedcropImages)
    }

    const getAspectThumbnail = (cropAspectParam) => {
        const sourceImage = document.getElementById('sourceImage');
        const thumbnailCanvas = document.createElement('canvas'); 
        if (!thumbnailCanvas || !sourceImage) return;
        
        const context = thumbnailCanvas.getContext('2d');

        const maxWidth = 200;
        const maxHeight = 200;

        const sourceWidth = sourceImage.naturalWidth; 
        const sourceHeight = sourceImage.naturalHeight;      
        const sourceAspectRatio = sourceWidth / sourceHeight;

        // Calculate dimensions to maintain aspect ratio
        let newWidth = maxWidth;
        let newHeight = maxHeight;
        if (sourceAspectRatio > 1) {
            newHeight = maxHeight / sourceAspectRatio;
        } else {
            newWidth = maxWidth * sourceAspectRatio;
        }

        thumbnailCanvas.width = newWidth;
        thumbnailCanvas.height = newHeight;

        context.drawImage(sourceImage, 0, 0, sourceWidth, sourceHeight, 0, 0, newWidth, newHeight);

        // Calculate dimensions of the area to be cropped out
        let croppedWidth = newWidth;
        let croppedHeight = newHeight;
        if (cropAspectParam > sourceAspectRatio) {
            croppedHeight = newWidth / cropAspectParam;
        } else {
            croppedWidth = newHeight * cropAspectParam;
        }

        // Calculate the position and size of the rectangles
        let topRectHeight = (newHeight - croppedHeight) / 2;
        let bottomRectHeight = topRectHeight;
        let leftRectWidth = (newWidth - croppedWidth) / 2;
        let rightRectWidth = leftRectWidth;


        // Apply dark overlay to the portions that will be cropped out
        context.fillStyle = 'rgba(0, 0, 0, 0.5)';
        context.fillRect(0, 0, newWidth, topRectHeight); // Top rectangle
        context.fillRect(0, newHeight - bottomRectHeight, newWidth, bottomRectHeight); // Bottom rectangle
        context.fillRect(0, 0, leftRectWidth, newHeight); // Left rectangle
        context.fillRect(newWidth - rightRectWidth, 0, rightRectWidth, newHeight); // Right rectangle

        const thumbnailUrl= thumbnailCanvas.toDataURL('image/jpg');
        return thumbnailUrl;

    };

    const handleCropUpdate = (cropDefinition) => {
        if (cropAspect ) {  setCropDefinition(cropDefinition); }
        else { setCropDefinition(null)}
    }
	return (
        <React.Fragment>
        {loading && 
        <DownloadBackdrop open={open} onClick={handleClose}>
          <CircularProgress color="inherit" variant='indeterminate' disableShrink={true} />
        </DownloadBackdrop>}
        {!loading &&
        <Dialog
            onClose={handleClose}
            disableEscapeKeyDown={true}
            aria-labelledby="simple-dialog-title"
            open={open}
            TransitionProps={{
                onEnter: handleOpen
            }}
            fullWidth={true}
            maxWidth='md'
        >
        <DialogTitle id="simple-dialog-title">{projectData.title}</DialogTitle>
        <DialogContent sx={{maxHeight:"100%", overflow:"hidden"}}>
            <Grid container justifyContent='space-around' direction="row">
                <Grid item xs={8} sx={{display: 'flex', alignItems: 'center'}}>
                    <Grid sx={{paddingRight: '16px'}}>
                        { !cropAspect  && <img id="sourceImage" src={url} style={{width:'100%', borderRadius: '8px'}} onLoad={createThumbailForAllAspect}/> }
                        { cropAspect > 0 && <CropImageAspect cropUpdate={handleCropUpdate} imgUrl={url} cropAspect={cropAspect} format={format} fileName={fileName} downloadClicked={downloadClicked} setDownloadClicked={setDownloadClicked} createThumbailForAllAspect={createThumbailForAllAspect}/> }  
                    </Grid>
                </Grid>
                <Grid item xs={4} sx={{background: '#f4f4f4', borderRadius: '8px'}} >
                    <Grid sx={{padding: '10px 10px 0px 10px', textAlign: 'center' }}>
                        <Typography variant="h6" sx={{fontSize: '14px', fontWeight: '700'}}>File Size and Format</Typography>
                    </Grid>
                    <Grid>
                        <hr style={{borderTop: '1px solid #33333333'}} />
                        <Grid sx={{padding: '10px 10px 0px 10px' }}>
                            <InputLabel >Format</InputLabel>
                            <Select onChange={(e)=> { setFormat(e.target.value) }} value={format} disabled={backgroundType == 'Transparent'}
                                sx={{width: '200px', maxWidth: '100%', height: '32px', background: 'white', fontSize: '14px', marginLeft: '4px'}}>
                                {formatTypes.map((type) => (
                                    <MenuItem key={type} value={type} disabled={disableOption(type)} sx={{justifyContent:'space-between'}}>
                                        {type + '(' + type.toLowerCase() + ')'}
                                        {isProOption(type) && <img src='/images/icons/proFinalIcon.png' style={{ height:'20px', float: 'right'}} />}
                                    </MenuItem>
                            ))}
                            </Select> 
                            <InputLabel sx={{marginTop: '10px'}}>Choose your Background</InputLabel>
                            <Select onChange={(e)=> { setBackgroundType(e.target.value); if(e.target.value == 'Transparent') {setFormat('PNG');}}} defaultValue={backgroundTypes[0]}
                                sx={{width: '200px', maxWidth: '100%', height: '32px', background: 'white', fontSize: '14px', marginLeft: '4px', justifyContent:'space-between' }}>
                                {backgroundTypes.map((type) => (
                                    <MenuItem key={type} value={type} disabled={disableOption(type)} sx={{justifyContent:'space-between'}}>
                                        {type}
                                        {isProOption(type) && <img src='/images/icons/proFinalIcon.png' style={{ height:'20px', float: 'right'}} />}
                                    </MenuItem>
                            ))}
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid sx={{padding: '10px 10px 0px 10px', textAlign: 'center' }}>
                        <Typography variant="h6" sx={{fontSize: '14px', fontWeight: '700'}}>Crop Your Board</Typography>
                        <Typography variant="h6" sx={{fontSize: '14px', fontWeight: '200', marginTop:'4px'}}>Aspect Ratio</Typography>
                    </Grid>
                    <Grid>
                        <hr style={{borderTop: '1px solid #33333333'}} />
                        <Grid sx={{padding: '10px 10px 0px 10px' }}>
                            <ScrollableGrid
                                style={{maxHeight:'30vh', overflow:'auto', paddingTop:'10px', paddingLeft:'4px'}}
                                getName={getName}
                                getSrc={getImageSrc} 
                                onClick={handleCropImagesClick} 
                                loadItems={()=>{}}
                                items={cropImages}
                                draggable={false}
                                imageStyle={{backgroundSize: 'contain'}}
                                getIsDisabled={getIsDisabled}
                />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>    
        <DialogActions>

        <DeepRenderButton onClose={onClose} format={format} backgroundType={backgroundType} cropDefinition={cropDefinition}   />
        <Tooltip title="A web based render.">
            <Button disabled={downloadClicked} 
                onClick={download} 
                variant="outlined"
                sx={{
                    background: 'white',
                    borderColor: (theme) => theme.palette.primary.shades[250],
                    '&:hover': { background: (theme) => theme.palette.primary.shades[100], borderColor: (theme) => theme.palette.primary.shades[250], color: (theme) => theme.palette.primary.light },
                    '&:active': { background: 'black', color: 'white' }
                }}
                >Download (Average Quality)</Button>
        </Tooltip>
        <Button 
            onClick={handleClose} 
            variant="outlined"
            sx={{
                background: 'white',
                borderColor: (theme) => theme.palette.primary.shades[250],
                '&:hover': { background: (theme) => theme.palette.primary.shades[100], borderColor: (theme) => theme.palette.primary.shades[250], color: (theme) => theme.palette.primary.light }
            }}
        >
            Close
        </Button>
        </DialogActions>

        </Dialog>    }
        </React.Fragment>
    );
}
